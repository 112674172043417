import React from 'react';

export const TitleImageSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5417 1H1.45833C0.654167 1 0 1.65417 0 2.45833V4.54167C0 5.34583 0.654167 6 1.45833 6H18.5417C19.3458 6 20 5.34583 20 4.54167V2.45833C20 1.65417 19.3458 1 18.5417 1Z"
      fill="currentColor"
    />
    <path
      d="M14.7109 8.42969H5.28906C4.9473 8.43006 4.61963 8.56599 4.37797 8.80766C4.1363 9.04932 4.00037 9.37698 4 9.71875V16.2812C4.00037 16.623 4.1363 16.9507 4.37797 17.1923C4.61963 17.434 4.9473 17.5699 5.28906 17.5703H14.7109C15.0527 17.5699 15.3804 17.434 15.622 17.1923C15.8637 16.9507 15.9996 16.623 16 16.2812V9.71875C15.9996 9.37698 15.8637 9.04932 15.622 8.80766C15.3804 8.56599 15.0527 8.43006 14.7109 8.42969ZM4.70312 9.71875C4.70312 9.56335 4.76486 9.41431 4.87474 9.30443C4.98463 9.19454 5.13366 9.13281 5.28906 9.13281H14.7109C14.8663 9.13281 15.0154 9.19454 15.1253 9.30443C15.2351 9.41431 15.2969 9.56335 15.2969 9.71875V16.2812C15.2969 16.297 15.2969 16.3122 15.2948 16.3281L12.3885 13.2288C12.3558 13.1937 12.3162 13.1657 12.2722 13.1465C12.2282 13.1273 12.1808 13.1174 12.1328 13.1172C12.0847 13.1172 12.0372 13.1271 11.9931 13.1462C11.949 13.1653 11.9093 13.1932 11.8764 13.2283L11.2152 13.9335L9.29922 11.826C9.26627 11.7898 9.2261 11.7608 9.1813 11.741C9.1365 11.7212 9.08805 11.7109 9.03906 11.7109C8.99007 11.7109 8.94162 11.7212 8.89682 11.741C8.85202 11.7608 8.81186 11.7898 8.77891 11.826L4.70312 16.3087C4.70312 16.2995 4.70312 16.2903 4.70312 16.2812V9.71875ZM5.28906 16.8672C5.24546 16.8671 5.20199 16.8623 5.15945 16.8527L9.03906 12.5852L10.7327 14.4482L9.97398 15.2575C9.94168 15.291 9.91636 15.3307 9.89951 15.3741C9.88265 15.4175 9.87458 15.4638 9.87578 15.5104C9.87698 15.5569 9.88741 15.6028 9.90648 15.6453C9.92555 15.6878 9.95287 15.7261 9.98685 15.7579C10.0208 15.7897 10.0608 15.8145 10.1044 15.8308C10.1481 15.847 10.1945 15.8545 10.2411 15.8526C10.2876 15.8508 10.3333 15.8398 10.3755 15.8201C10.4178 15.8005 10.4557 15.7726 10.487 15.7382L12.1328 13.9827L14.8263 16.8557C14.7883 16.8633 14.7497 16.8672 14.7109 16.8672H5.28906Z"
      fill="currentColor"
    />
    <path
      d="M13.1875 12.6484C13.4656 12.6484 13.7375 12.566 13.9688 12.4114C14.2 12.2569 14.3803 12.0373 14.4867 11.7803C14.5931 11.5234 14.621 11.2406 14.5667 10.9678C14.5125 10.6951 14.3785 10.4445 14.1819 10.2478C13.9852 10.0512 13.7346 9.91722 13.4618 9.86296C13.1891 9.8087 12.9063 9.83655 12.6494 9.94298C12.3924 10.0494 12.1728 10.2297 12.0182 10.4609C11.8637 10.6922 11.7812 10.9641 11.7812 11.2422C11.7817 11.615 11.93 11.9724 12.1936 12.2361C12.4572 12.4997 12.8147 12.648 13.1875 12.6484V12.6484ZM13.1875 10.5391C13.3266 10.5391 13.4625 10.5803 13.5781 10.6576C13.6938 10.7348 13.7839 10.8446 13.8371 10.9731C13.8903 11.1016 13.9042 11.243 13.8771 11.3794C13.85 11.5158 13.783 11.641 13.6847 11.7394C13.5863 11.8377 13.4611 11.9047 13.3247 11.9318C13.1883 11.9589 13.0469 11.945 12.9184 11.8918C12.7899 11.8386 12.6801 11.7484 12.6029 11.6328C12.5256 11.5172 12.4844 11.3813 12.4844 11.2422C12.4844 11.0557 12.5585 10.8769 12.6903 10.745C12.8222 10.6131 13.001 10.5391 13.1875 10.5391V10.5391Z"
      fill="currentColor"
    />
  </svg>
);

export default TitleImageSvg;
