import { EmojiClickData } from 'emoji-picker-react';
import { Editor, Element as SlateElement, Text, Transforms } from 'slate';

import { RichTextEditorType } from './SlateRichTextEditor';
import {
  CuripodDecendant,
  CuripodElement,
  CuripodText,
  getIsTextHighlighted,
  isCuripodElement,
  LIST_TYPES,
  TEXT_ALIGN_TYPES,
  TYPE_LIST_TYPE,
} from './slateUtil';

export const getBiggerSize = (currentSize?: number, scale?: number) => {
  if (!currentSize) return 30;
  const scaledSize = scale ? currentSize * scale : currentSize;

  if (scaledSize <= 10) return scaledSize + 1;
  if (scaledSize <= 30) return scaledSize + 2;
  return Math.min(scaledSize + 5, 300);
};
//TODO: add unit tests
export const getSmallerSize = (currentSize?: number, scale?: number) => {
  if (!currentSize) return 30;
  const scaledSize = scale ? currentSize * scale : currentSize;
  if (scaledSize <= 1) return 1;

  if (scaledSize <= 10) return scaledSize - 1;
  if (scaledSize <= 30) return scaledSize - 2;
  return scaledSize - 5;
};

export const getActiveNode = (editor: Editor) => {
  const { selection } = editor;
  if (!selection) return;
  const active = editor.children[selection.anchor.path[0]];
  return active as CuripodElement;
};
export const getActiveSize = (editor: Editor) => {
  const active = getActiveNode(editor);
  if (!active) return;
  return active.size;
};
export const getActiveNestedNode = (editor: Editor): CuripodElement | undefined => {
  const { selection } = editor;
  if (!selection) return;
  const active = editor.children[selection.anchor.path[0]];

  if (isCuripodElement(active)) {
    if (active.type === 'bulleted-list' || active.type === 'numbered-list') {
      return active.children?.[selection.anchor.path[1]] as CuripodElement;
    }
    return active;
  }
};

const MAX_FONT_SIZE = 999;
const MIN_FONT_SIZE = 1;
export const toggleSize = ({
  increaseOrDecrease,
  size,
  editor,
  scale,
}: {
  editor: Editor;
  increaseOrDecrease?: 'increase' | 'decrease';
  size?: number;
  scale?: number;
}) => {
  const activeSize = getActiveSize(editor);

  const newSize = size
    ? size
    : increaseOrDecrease === 'increase'
    ? getBiggerSize(activeSize, scale)
    : getSmallerSize(activeSize, scale);
  const sizeToUse = Math.max(MIN_FONT_SIZE, Math.min(MAX_FONT_SIZE, newSize));
  Transforms.setNodes(
    editor,
    { size: sizeToUse },
    {
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        (n.type === 'paragraph' ||
          n.type === 'bulleted-list' ||
          n.type === 'numbered-list'),
    },
  );
  return { content: editor.children, newSize: sizeToUse };
};

export const isMarkActive = (editor: Editor, format: string, value?: string) => {
  const marks = Editor.marks(editor);
  if (!!value) {
    // @ts-ignore
    if (marks && marks[format] === value) return true;
    return false;
  }

  // @ts-ignore
  if (marks && marks[format]) return true;
  return false;
};
export const setMark = (editor: Editor, format: string, value: boolean | string) => {
  Transforms.setNodes(
    editor,
    { [format]: value },
    {
      match: n => {
        return !Editor.isEditor(n) && Text.isText(n);
      },
    },
  );
  if (format === 'color') {
    // checks if the whole leaf element of a list-item is selected and if that is the case we need to set the color of the list-item
    const activeNode = getActiveNestedNode(editor);
    if (activeNode && activeNode.type === 'list-item') {
      Transforms.setNodes(
        editor,
        { [format]: value.toString() },
        {
          match: n => {
            return (
              !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item'
            );
          },
        },
      );
    }
  }
};

export const toggleMark = (
  editor: RichTextEditorType,
  format: string,
  value?: string,
) => {
  const isActive = isMarkActive(editor, format, value);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, value ? value : true);
  }
};

export const isAlignActive = (editor: Editor, format: TEXT_ALIGN_TYPES) => {
  const { selection } = editor;
  // You're not in focus
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n['align'] === format,
    }),
  );

  return !!match;
};
/**
 *
 * A type can be bulleted list, numbered list + links, custom type
 */
export const isTypeActive = (editor: Editor, format: TYPE_LIST_TYPE) => {
  const { selection } = editor;
  if (!selection) return false;
  // You're not in focus

  const [match] = Array.from(
    Editor.nodes(editor, {
      match: n =>
        // @ts-ignore
        !Editor.isEditor(n) && SlateElement.isElement(n) && n['type'] === format,
    }),
  );

  return !!match;
};

export const toggleAlign = (editor: Editor, format: TEXT_ALIGN_TYPES) => {
  const hasHighlightedText = getIsTextHighlighted(editor);

  const isActive = isAlignActive(editor, format);

  const newProperties: Partial<CuripodElement> | undefined = {
    align: isActive ? undefined : format,
  };

  Transforms.setNodes<SlateElement>(editor, newProperties, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      (n.type === 'paragraph' || n.type === 'list-item'),
    at: hasHighlightedText ? undefined : [],
  });
  if (hasHighlightedText) {
    Transforms.collapse(editor, { edge: 'end' });
  }
  return newProperties.align;
};

/**
 *
 * @param editor the slate editor
 * @param format bulleted list etc
 * @param currentContent * + enter, - + enter or 1. enter
 * @returns
 */
export const toggleType = ({
  editor,
  format,
  currentContent,
  formatAll,
}: {
  editor: Editor;
  format: TYPE_LIST_TYPE;
  currentContent?: string;
  formatAll?: boolean;
}) => {
  // isActive means: this block is a paragraph, and we should transform it to a list block
  const isActive = isTypeActive(editor, format);

  const activeNode = getActiveNode(editor);
  const newProperties: Partial<CuripodElement> = {
    type: isActive ? 'paragraph' : 'list-item',
    // size: activeNode?.size ? parseInt(activeNode.size) : 80
  };
  if (!isActive) {
    newProperties['align'] = 'left';
  }

  // runwrapping all nodes to a flat structure
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type as TYPE_LIST_TYPE),
    split: true,
    at: formatAll ? [] : undefined,
  });

  // transforming the list-items to paragraphs
  if (isActive) {
    Transforms.setNodes<SlateElement>(editor, newProperties, {
      at: formatAll ? [] : undefined,
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item',
    });
    return;
  }
  Transforms.setNodes<SlateElement>(editor, newProperties, {
    at: formatAll ? [] : undefined,
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'paragraph',
  });

  const newBlock: CuripodDecendant = {
    type: format,
    children: [],
    size: activeNode?.size || 80,
    color: activeNode?.color || 'black',
  };

  Transforms.wrapNodes(editor, newBlock, {
    at: formatAll ? [] : undefined,
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item',
  });

  if (currentContent !== undefined) {
    // Clear the activator symbol and inserting empty or existing
    Transforms.insertText(editor, currentContent ? currentContent : '', {
      at: editor.selection?.anchor.path,
    });
  }
};

export const toggleEmojiPicked = (editor: Editor, emoji: EmojiClickData) => {
  const match = getSelectionMatch(editor);
  if (!match) return;
  Transforms.insertText(editor, emoji.emoji);
  // editor.insertText(emoji.emoji);
  return { content: editor.children };
};

export const insertLink = (editor: Editor, link: string, linkText: string) => {
  const match = getSelectionMatch(editor);
  if (!match) return;
  const newLink: CuripodText = {
    text: linkText,
    link,
  };
  Editor.insertNode(editor, newLink);
  // editor.insertText(emoji.emoji);
  return { content: editor.children };
};
const getSelectionMatch = (editor: Editor) => {
  const { selection } = editor;

  if (!selection) return;
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n => {
        return (
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          (n.type === 'paragraph' ||
            n.type === 'bulleted-list' ||
            n.type === 'numbered-list')
        );
      },
    }),
  );

  return match as CuripodElement[];
};
