export const SlideHeaderText = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5417 1H1.45833C0.654167 1 0 1.58875 0 2.3125V4.1875C0 4.91125 0.654167 5.5 1.45833 5.5H18.5417C19.3458 5.5 20 4.91125 20 4.1875V2.3125C20 1.58875 19.3458 1 18.5417 1Z"
        fill="currentColor"
      />
      <path
        d="M18.1562 8H1.84375C1.378 8 1 8.224 1 8.5C1 8.776 1.378 9 1.84375 9H18.1562C18.622 9 19 8.776 19 8.5C19 8.224 18.622 8 18.1562 8Z"
        fill="currentColor"
      />
      <path
        d="M18.1562 10.5H1.84375C1.378 10.5 1 10.724 1 11C1 11.276 1.378 11.5 1.84375 11.5H18.1562C18.622 11.5 19 11.276 19 11C19 10.724 18.622 10.5 18.1562 10.5Z"
        fill="currentColor"
      />
      <path
        d="M18.1562 8H1.84375C1.378 8 1 8.224 1 8.5C1 8.776 1.378 9 1.84375 9H18.1562C18.622 9 19 8.776 19 8.5C19 8.224 18.622 8 18.1562 8Z"
        fill="currentColor"
      />
      <path
        d="M18.1562 13H1.84375C1.378 13 1 13.224 1 13.5C1 13.776 1.378 14 1.84375 14H18.1562C18.622 14 19 13.776 19 13.5C19 13.224 18.622 13 18.1562 13Z"
        fill="currentColor"
      />
    </svg>
  );
};
