export function getRectangleCornerCoordinate({
  x,
  y,
  w,
  h,
  theta,
  position,
}: {
  x: number;
  y: number;
  w: number;
  h: number;
  theta: number;
  position: 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';
}): { x: number; y: number } {
  if (position === 'top-left') {
    const newX = x + w / 2 - (Math.cos(theta) * w) / 2 + (Math.sin(theta) * h) / 2;
    const newY = y + h / 2 - (Math.sin(theta) * w) / 2 - (Math.cos(theta) * h) / 2;
    return { x: newX, y: newY };
  }
  if (position === 'top-right') {
    x = x + w / 2 + (Math.cos(theta) * w) / 2 + (Math.sin(theta) * h) / 2;
    y = y + h / 2 + (Math.sin(theta) * w) / 2 - (Math.cos(theta) * h) / 2;
    return { x, y };
  }
  if (position === 'bottom-left') {
    // minus plus
    x = x + w / 2 - (Math.cos(theta) * w) / 2 - (Math.sin(theta) * h) / 2;
    y = y + h / 2 - (Math.sin(theta) * w) / 2 + (Math.cos(theta) * h) / 2;
    return { x, y };
  }

  if (position === 'bottom-right') {
    // plus plus
    x = x + w / 2 + (Math.cos(theta) * w) / 2 - (Math.sin(theta) * h) / 2;
    y = y + h / 2 + (Math.sin(theta) * w) / 2 + (Math.cos(theta) * h) / 2;
    return { x, y };
  }

  return { x, y };
}

export function getAllRectangleCornerCoordinate({
  x,
  y,
  w,
  h,
  theta,
}: {
  x: number;
  y: number;
  w: number;
  h: number;
  theta: number;
}): { x: number; y: number }[] {
  const topLeft = getRectangleCornerCoordinate({
    x,
    y,
    w,
    h,
    theta,
    position: 'top-left',
  });
  const topRight = getRectangleCornerCoordinate({
    x,
    y,
    w,
    h,
    theta,
    position: 'top-right',
  });

  const bottomRight = getRectangleCornerCoordinate({
    x,
    y,
    w,
    h,
    theta,
    position: 'bottom-right',
  });

  const bottomLeft = getRectangleCornerCoordinate({
    x,
    y,
    w,
    h,
    theta,
    position: 'bottom-left',
  });

  return [topLeft, topRight, bottomRight, bottomLeft];
}
