export const SlideImageTextSvg = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5417 1H1.45833C0.654167 1 0 1.58875 0 2.3125V4.1875C0 4.91125 0.654167 5.5 1.45833 5.5H18.5417C19.3458 5.5 20 4.91125 20 4.1875V2.3125C20 1.58875 19.3458 1 18.5417 1Z"
      fill="currentColor"
    />
    <path
      d="M8.14062 8H1.85937C1.63153 8.00024 1.41309 8.08947 1.25198 8.2481C1.09087 8.40673 1.00025 8.62181 1 8.84615V13.1538C1.00025 13.3782 1.09087 13.5933 1.25198 13.7519C1.41309 13.9105 1.63153 13.9998 1.85937 14H8.14062C8.36847 13.9998 8.58691 13.9105 8.74802 13.7519C8.90913 13.5933 8.99975 13.3782 9 13.1538V8.84615C8.99975 8.62181 8.90913 8.40673 8.74802 8.2481C8.58691 8.08947 8.36847 8.00024 8.14062 8ZM1.46875 8.84615C1.46875 8.74415 1.5099 8.64632 1.58316 8.57419C1.65642 8.50206 1.75577 8.46154 1.85937 8.46154H8.14062C8.24422 8.46154 8.34358 8.50206 8.41684 8.57419C8.49009 8.64632 8.53125 8.74415 8.53125 8.84615V13.1538C8.53125 13.1642 8.53125 13.1742 8.52984 13.1846L6.59234 11.1502C6.57051 11.1271 6.54412 11.1088 6.5148 11.0962C6.48548 11.0836 6.45386 11.077 6.42187 11.0769C6.38983 11.0769 6.35813 11.0834 6.32873 11.0959C6.29933 11.1085 6.27285 11.1268 6.25094 11.1498L5.81016 11.6128L4.53281 10.2294C4.51084 10.2056 4.48407 10.1866 4.4542 10.1736C4.42433 10.1606 4.39203 10.1538 4.35937 10.1538C4.32671 10.1538 4.29441 10.1606 4.26455 10.1736C4.23468 10.1866 4.2079 10.2056 4.18594 10.2294L1.46875 13.1718C1.46875 13.1658 1.46875 13.1598 1.46875 13.1538V8.84615ZM1.85937 13.5385C1.8303 13.5384 1.80132 13.5352 1.77297 13.5289L4.35937 10.7277L5.48844 11.9506L4.98266 12.4818C4.96112 12.5039 4.94424 12.5299 4.933 12.5584C4.92176 12.5869 4.91639 12.6173 4.91719 12.6478C4.91798 12.6784 4.92494 12.7085 4.93765 12.7364C4.95036 12.7643 4.96858 12.7894 4.99123 12.8103C5.01389 12.8312 5.04054 12.8475 5.06963 12.8582C5.09873 12.8688 5.12968 12.8737 5.16071 12.8725C5.19173 12.8713 5.2222 12.864 5.25035 12.8511C5.2785 12.8383 5.30377 12.82 5.32469 12.7974L6.42187 11.6451L8.2175 13.5309C8.19219 13.5359 8.16644 13.5385 8.14062 13.5385H1.85937Z"
      fill="currentColor"
    />
    <path
      d="M8.14062 8H1.85937C1.63153 8.00024 1.41309 8.08947 1.25198 8.2481C1.09087 8.40673 1.00025 8.62181 1 8.84615V13.1538C1.00025 13.3782 1.09087 13.5933 1.25198 13.7519C1.41309 13.9105 1.63153 13.9998 1.85937 14H8.14062C8.36847 13.9998 8.58691 13.9105 8.74802 13.7519C8.90913 13.5933 8.99975 13.3782 9 13.1538V8.84615C8.99975 8.62181 8.90913 8.40673 8.74802 8.2481C8.58691 8.08947 8.36847 8.00024 8.14062 8ZM1.46875 8.84615C1.46875 8.74415 1.5099 8.64632 1.58316 8.57419C1.65642 8.50206 1.75577 8.46154 1.85937 8.46154H8.14062C8.24422 8.46154 8.34358 8.50206 8.41684 8.57419C8.49009 8.64632 8.53125 8.74415 8.53125 8.84615V13.1538C8.53125 13.1642 8.53125 13.1742 8.52984 13.1846L6.59234 11.1502C6.57051 11.1271 6.54412 11.1088 6.5148 11.0962C6.48548 11.0836 6.45386 11.077 6.42187 11.0769C6.38983 11.0769 6.35813 11.0834 6.32873 11.0959C6.29933 11.1085 6.27285 11.1268 6.25094 11.1498L5.81016 11.6128L4.53281 10.2294C4.51084 10.2056 4.48407 10.1866 4.4542 10.1736C4.42433 10.1606 4.39203 10.1538 4.35937 10.1538C4.32671 10.1538 4.29441 10.1606 4.26455 10.1736C4.23468 10.1866 4.2079 10.2056 4.18594 10.2294L1.46875 13.1718C1.46875 13.1658 1.46875 13.1598 1.46875 13.1538V8.84615ZM1.85937 13.5385C1.8303 13.5384 1.80132 13.5352 1.77297 13.5289L4.35937 10.7277L5.48844 11.9506L4.98266 12.4818C4.96112 12.5039 4.94424 12.5299 4.933 12.5584C4.92176 12.5869 4.91639 12.6173 4.91719 12.6478C4.91798 12.6784 4.92494 12.7085 4.93765 12.7364C4.95036 12.7643 4.96858 12.7894 4.99123 12.8103C5.01389 12.8312 5.04054 12.8475 5.06963 12.8582C5.09873 12.8688 5.12968 12.8737 5.16071 12.8725C5.19173 12.8713 5.2222 12.864 5.25035 12.8511C5.2785 12.8383 5.30377 12.82 5.32469 12.7974L6.42187 11.6451L8.2175 13.5309C8.19219 13.5359 8.16644 13.5385 8.14062 13.5385H1.85937Z"
      fill="currentColor"
    />
    <path
      d="M7.125 10.7692C7.31042 10.7692 7.49167 10.7151 7.64585 10.6137C7.80002 10.5123 7.92018 10.3681 7.99113 10.1994C8.06209 10.0307 8.08066 9.84515 8.04448 9.66609C8.00831 9.48703 7.91902 9.32255 7.78791 9.19346C7.6568 9.06436 7.48975 8.97645 7.3079 8.94083C7.12604 8.90522 6.93754 8.9235 6.76623 8.99336C6.59493 9.06323 6.44851 9.18154 6.3455 9.33334C6.24248 9.48514 6.1875 9.6636 6.1875 9.84617C6.18779 10.0909 6.28665 10.3255 6.46241 10.4986C6.63816 10.6716 6.87645 10.769 7.125 10.7692ZM7.125 9.38463C7.21771 9.38463 7.30834 9.4117 7.38542 9.46242C7.46251 9.51313 7.52259 9.58521 7.55807 9.66955C7.59355 9.75388 7.60283 9.84668 7.58474 9.93621C7.56665 10.0257 7.52201 10.108 7.45645 10.1725C7.3909 10.2371 7.30738 10.281 7.21645 10.2988C7.12552 10.3166 7.03127 10.3075 6.94562 10.2726C6.85996 10.2376 6.78675 10.1785 6.73525 10.1026C6.68374 10.0267 6.65625 9.93745 6.65625 9.84617C6.65625 9.72376 6.70564 9.60637 6.79354 9.51981C6.88145 9.43326 7.00068 9.38463 7.125 9.38463Z"
      fill="currentColor"
    />
    <path
      d="M18.5781 8H10.4219C10.189 8 10 8.224 10 8.5C10 8.776 10.189 9 10.4219 9H18.5781C18.811 9 19 8.776 19 8.5C19 8.224 18.811 8 18.5781 8Z"
      fill="currentColor"
    />
    <path
      d="M18.5781 10.5H10.4219C10.189 10.5 10 10.724 10 11C10 11.276 10.189 11.5 10.4219 11.5H18.5781C18.811 11.5 19 11.276 19 11C19 10.724 18.811 10.5 18.5781 10.5Z"
      fill="currentColor"
    />
    <path
      d="M18.5781 8H10.4219C10.189 8 10 8.224 10 8.5C10 8.776 10.189 9 10.4219 9H18.5781C18.811 9 19 8.776 19 8.5C19 8.224 18.811 8 18.5781 8Z"
      fill="currentColor"
    />
    <path
      d="M18.5781 13H10.4219C10.189 13 10 13.224 10 13.5C10 13.776 10.189 14 10.4219 14H18.5781C18.811 14 19 13.776 19 13.5C19 13.224 18.811 13 18.5781 13Z"
      fill="currentColor"
    />
  </svg>
);
