import { GeneratorFormState } from '../components/_organisms/DesignerTabPane/PromptsTab/GeneratorForm';
import {
  CuripodDecendant,
  CuripodElement,
  CuripodText,
  isCuripodText,
  parseSlateStringToText,
  parseStringToSlateObject,
} from '../components/SlideComponents/slateUtil';
import { Slide } from '../components/SlideComponents/slide.types';
import {
  AIResourceTypes,
  Prompt,
  PromptInput,
  PromptInputData,
  promptModels,
  PromptSingleTestCaseResult,
} from '../services/backendService/types';
import { isDevelopment } from './client';

export const MAX_LIMIT_LEARNING_OBJECTIVE = 200;
export const MAX_LIMIT_PROMPT = 80;
export const MAX_LIMIT_UPLOADEDTEXT = 6000;

export const validSlidesFileTypes = [
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', //pptx
  'application/pdf',
  'pptx',
  'ppt',
  'key',
  'pdf',
];

/** How to add a new generative image model */
export const textResources = [AIResourceTypes.openai];

export const imageResources = [
  AIResourceTypes.openaiImage,
  AIResourceTypes.stableDiffusionImage,
  // AIResourceTypes.midjourney,
];

export const fileGenerativeImages = [
  AIResourceTypes.openaiImage,
  // AIResourceTypes.midjourney,
];

export const availableImageModels = [
  {
    displayName: 'DALL·E 3',
    value: promptModels.dalle3,
  },
  // {
  //   displayName: 'Midjourney',
  //   value: promptModels.midjourney,
  // },
];

export const isGenerativeImagePrompt = (prompt: Prompt) => {
  return (imageResources as string[]).includes(prompt.promptOutput.resource.type);
};
export const isGenerativeTextPrompt = (prompt: Prompt) => {
  return (textResources as string[]).includes(prompt.promptOutput.resource.type);
};

export const getFullLessonGeneratorUrl = () => {
  if (isDevelopment()) {
    return '/generators/Fun%20debate%20practice/g/7e8a14e3-6fdf-4971-b654-8e444e0e1fbb';
  }
  return '/generators/Full-lesson/g/753d7c32-4f9b-42bf-bb53-12419c28425d';
};
export const generatorFormStateToUserInputFormData = (
  formState: GeneratorFormState,
  promptInputs: PromptInput[],
): PromptInputData[] => {
  return promptInputs.map(input => {
    if (input.type === 'slidesUpload') {
      const value = formState?.[input.id];
      if (typeof value === 'string' || typeof value === 'number') {
        throw new Error('Invalid value for file or slide uploads');
      }

      return {
        id: input.id,
        type: input.type,
        fileType: value.fileType,
        value: value.filePath,
      };
    }

    return {
      id: input.id,
      type: input.type,
      value: (formState?.[input.id] || input.defaultValue || '') as string,
    };
  });
};

export const createSimpleSlateParagraphsFromAIOutput = ({
  align,
  paragraphColor,
  size,
  textChild,
}: {
  align?: 'left' | 'center' | 'right';
  paragraphColor?: string;
  size?: number;
  textChild?: CuripodDecendant;
}): CuripodElement[] => {
  let bold: boolean | undefined;
  let italic: boolean | undefined;
  let underline: boolean | undefined;
  let text: string | undefined;
  let link: string | undefined;

  if (textChild && isCuripodText(textChild)) {
    bold = textChild.bold;
    italic = textChild.italic;
    underline = textChild.underline;
    text = textChild.text;
    link = textChild.link;
  }

  if (!text && textChild && isCuripodText(textChild))
    return [
      {
        type: 'paragraph',
        children: [
          {
            text: text,
            underline,
            bold,
            italic,
            color: textChild?.color,
            link,
          } as CuripodText,
        ],
        align,
        size,
        color: paragraphColor, // deprecated in favor for child color (leaf node)
      },
    ];

  return [
    {
      type: 'paragraph',
      children: [
        {
          text: text || '',
          underline,
          bold,
          italic,
          color: paragraphColor,
          link,
        } as CuripodText,
      ],
      align,
      size,
      color: paragraphColor,
    },
  ];
};

export const updateGeneratedTextElement = (
  slateTextString: string,
  newTextValue: string,
): string | undefined => {
  const blocks = parseStringToSlateObject(slateTextString);
  const firstParagraph = blocks.find(b => b.type === 'paragraph');
  if (!firstParagraph) return;
  const textChild = firstParagraph?.children?.[0];
  if (textChild && isCuripodText(textChild)) {
    const newParagraphs = createSimpleSlateParagraphsFromAIOutput({
      align: firstParagraph.align,
      paragraphColor: textChild.color,
      size: firstParagraph.size,
      textChild: {
        ...textChild,
        text: newTextValue,
        color: textChild.color,
      },
    });
    const newSlateString = JSON.stringify(newParagraphs);
    return newSlateString;
  }
};

export const reconnectPromptDependantElements = ({
  promptResultData,
  slides,
}: {
  promptResultData: PromptSingleTestCaseResult;
  slides: Slide[];
}) => {
  //Find all connected elements and update them - keeping the original order as this determines the z-index
  const updatedSlides = slides.reduce((acc, slide) => {
    const updatedElements = slide.elements.map(element => {
      //check if element has an element prompt source id if not return the element
      if (!element.promptSourceId) return element;
      const elementPromptSrcId = element.promptSourceId.split('.')[0];

      //check if there is a corresponding value in the prompt result data
      const prompt = promptResultData[element.promptSourceId];

      if (!prompt?.id) return element;
      const promptId = prompt?.id.split('.')[0];

      if (
        elementPromptSrcId === promptId &&
        element.id &&
        promptResultData?.[element.promptSourceId]?.value
      ) {
        //if element has a prompt source id check if the prompt source id is the same as the prompt id and update it
        if (element.type === 'generatorText' && element.textValue) {
          const newSlateString = updateGeneratedTextElement(
            element.textValue,
            promptResultData[element.promptSourceId].value,
          );
          if (!newSlateString) return element;

          return {
            ...element,
            textValue: newSlateString,
          };
        }

        if (element.type === 'generatorImage') {
          return {
            ...element,
            mediaFileId: promptResultData?.[element.promptSourceId]?.value,
          };
        }
      }
      return element;
    });

    if (slide.type !== 'poll' || !slide.pollOptions)
      return [
        ...acc,
        {
          ...slide,
          elements: updatedElements,
        },
      ];

    const updatedPollOptions = slide.pollOptions.map(pollOption => {
      if (!pollOption.promptSourceId) return pollOption;

      const elementPromptSrcId = pollOption.promptSourceId.split('.')[0];
      //check if there is a corresponding value in the prompt result data
      const prompt = promptResultData[pollOption.promptSourceId];

      if (!prompt?.id) return pollOption;
      const promptId = prompt?.id.split('.')[0];
      if (
        elementPromptSrcId === promptId &&
        pollOption.id &&
        promptResultData?.[pollOption.promptSourceId]?.value
      ) {
        const newSlateString = updateGeneratedTextElement(
          pollOption.displayName,
          promptResultData[pollOption.promptSourceId].value,
        );
        if (!newSlateString) return pollOption;

        return {
          ...pollOption,
          displayName: newSlateString,
        };
      }
      return pollOption;
    });

    return [
      ...acc,
      {
        ...slide,
        elements: updatedElements,
        pollOptions: updatedPollOptions,
      },
    ];
  }, [] as Slide[]);
  return { slides: updatedSlides };
};

export const fromGenericMiltiTestCaseResultToSpecificTestCaseResult = (
  data: PromptSingleTestCaseResult,
  slideId: string,
) => {
  // Add the slide id and the answer id to the unique id so we can store responses from multiple slides in the same cache key
  return Object.entries(data).reduce((acc, [key, value]) => {
    const [coreKeyId, answerId] = key.split('.');

    if (
      coreKeyId === 'prompt' ||
      coreKeyId === 'slide-question' ||
      coreKeyId === 'slide-question-answer'
    ) {
      const newUniqueId = `${coreKeyId}.${answerId}.${slideId}`;
      acc[newUniqueId] = { ...value, id: newUniqueId };
      return acc;
    }
    return { ...acc, [coreKeyId]: value };
  }, {} as PromptSingleTestCaseResult);
};

export const fromGenericTestCaseResultToSlideSpecificTestCaseResult = (
  data: PromptSingleTestCaseResult,
  slideId: string,
) => {
  // Add the slide id to the unique id so we can store responses from multiple slides in the same cache key
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (key === 'prompt' || key === 'slide-question' || key === 'slide-question-answer') {
      const newUniqueId = `${key}.${slideId}`;
      acc[newUniqueId] = { ...value, id: newUniqueId };
      return acc;
    }
    return { ...acc, [key]: value };
  }, {} as PromptSingleTestCaseResult);
};
