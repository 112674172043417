import { Modifier } from '@dnd-kit/core';

import AIImageFeedbackSvg from '../../assets/icons/AIImageFeedbackSvg';
import { CloudSvg } from '../../assets/icons/CloudSvg';
import DrawingsSvg from '../../assets/icons/DrawingsSvg';
import GridSvg from '../../assets/icons/GridSvg';
import InstantFeedbackSvg from '../../assets/icons/InstantFeedbackSvg';
import PodiumSvg from '../../assets/icons/PodiumSvg';
import { PollSvg } from '../../assets/icons/PollSvg';
import QuestionsSvg from '../../assets/icons/QuestionsSvg';
import VotingSvg from '../../assets/icons/VotingSvg';
import theme from '../../assets/theme';
import { RoundMedia } from '../../services/backendService/types';
import { CropConfig } from '../../utils/imageCropUtils';
import { getAllRectangleCornerCoordinate } from '../../utils/math';
import { getIndexToColor } from '../../utils/roundUtils';
import { IframeAppSmash } from '../_organisms/DesignerTabPane/BackgroundTab/AppSmashUtils';
import {
  createAiFeedbackPrompt,
  createAiMultiModalFeedbackPrompt,
} from '../_organisms/DesignerTabPane/PromptsTab/newPromptTemplates';
import { NumberSize } from '../_organisms/Resizable';
import { Direction } from '../_organisms/Resizable/resizer';
import {
  calculateHeightOfSlateText,
  isCuripodText,
  parseSlateStringToText,
  parseStringToSlateObject,
  setColorOnAllSlateParagraphTexts,
} from './slateUtil';
import {
  MediaMimeType,
  MediaObjectFit,
  PollOptions,
  Slide,
  SlideContentType,
  SlideElement,
  SlideElementAIImageType,
  SlideElementAITextType,
  SlideElementIframeType,
  SlideElementImageType,
  SlideElementShapeType,
  SlideElementSvgType,
  SlideElementTextType,
  SlideType,
} from './slide.types';

export const RICHTEXTEDITOR_ID_PREFIX = 'slate-editor-elementid-';

export const PREVENT_UNSELECT_SLIDE_ELEMENT_CLASSNAME = 'prevent-unselect-slide-element';
export const SLIDE_ASPECT = { width: 1920, height: 1080 };
export const isSlideElementText = (
  element: SlideElement,
): element is SlideElementTextType => {
  return element.type === 'text' || element.type === 'generatorText';
};
export const isSlideElementGeneratorImage = (
  element: SlideElement,
): element is SlideElementAIImageType => {
  return element.type === 'generatorImage';
};
export const isSlideElementGeneratorText = (
  element: SlideElement,
): element is SlideElementAITextType => {
  if (element.isPollOption && element.promptSourceId) return true;
  return element.type === 'generatorText';
};
export const isSlideElementSvg = (
  element: SlideElement,
): element is SlideElementSvgType => {
  if (element.type !== 'svg') return false;

  return true;
};
export const isSlideElementShape = (
  element: SlideElement,
): element is SlideElementShapeType => {
  if (element.type !== 'shape') return false;

  return true;
};
export const isSlideElementImage = (
  element: SlideElement,
): element is SlideElementImageType => {
  if (element.type === 'image' || element.type === 'generatorImage') return true;
  return false;
};
export const isSlideElementIframe = (
  element: SlideElement,
): element is SlideElementIframeType => {
  if (element.type !== 'iframe') return false;

  return true;
};

export const createPollOptions = (textColor?: string): PollOptions[] => {
  return Array.from(Array(4).keys()).map(index => ({
    id: generateUniqueId().toString(),
    displayName: JSON.stringify([
      {
        type: 'paragraph',
        children: [{ text: `Option ${index + 1}` }],
        align: 'center',
        size: 50,
        color: textColor || theme.colors.black,
      },
    ]),
    color: getIndexToColor(index),
  }));
};
const aspect = {
  height: 1080,
  width: 1920,
};
export const createNewEmptySlide = ({
  type,
  contentType,
  id,
  width = 1920,
  height = 1080,
  textValue,
  includeMedia = false,
  fullscreenThumbnail,
  fullScreenEmpty = false,
  backgroundMedia,
  textColor,
  elements = [],
}: {
  id?: string;
  type?: SlideType;
  contentType?: SlideContentType;
  width?: number;
  height?: number;
  textValue?: string;
  includeMedia?: boolean;
  fullscreenThumbnail?: string;
  fullScreenEmpty?: boolean;
  backgroundMedia?: RoundMedia;
  textColor?: string;
  elements?: SlideElement[];
}): Slide => {
  const emptySlide: Slide = {
    id: id ? id : generateUniqueId().toString(),
    type: type ? type : 'slide',
    width,
    height,
    version: 1,
    elements: elements,
    contextElements: [],
    backgroundMedia: backgroundMedia,
  };
  // fetched from manual uploaded image to DCN
  const mediaData = {
    width: 800,
    height: 532,
    url:
      'https://storage.googleapis.com/curipod-images-cdn-bucket/shooting_to_the_moon.png',
  };

  // slides
  if (type === 'slide') {
    const randomTip = tips[Math.floor(Math.random() * tips.length)];
    if (!contentType) {
      return {
        ...emptySlide,
        backgroundMedia: undefined,
      };
    }
    if (contentType === 'header_text') {
      const headerElement = createTextElement({
        slateTextValue: JSON.stringify([
          {
            type: 'paragraph',
            children: [
              { text: 'A curipod slide', color: textColor || theme.colors.black },
            ],
            align: 'center',
            size: 100,
          },
        ]),
        x: aspect.width * 0.05,
        y: aspect.height * 0.1,
        fontSize: 100,
        width: aspect.width * 0.9,
      });

      const contentTextElement = createTextElement({
        slateTextValue: JSON.stringify([
          {
            type: 'paragraph',
            children: [{ text: randomTip, color: textColor || theme.colors.black }],
            align: 'left',
            size: 60,
          },
        ]),
        x: aspect.width * 0.05,
        y: aspect.height * 0.25,
        fontSize: 70,
        width: aspect.width * 0.9,
      });
      return {
        ...emptySlide,
        elements: [headerElement, contentTextElement],
      };
    }
    if (contentType === 'header') {
      const headerElement = createTextElement({
        slateTextValue: JSON.stringify([
          {
            type: 'paragraph',
            children: [
              { text: 'A curipod slide', color: textColor || theme.colors.black },
            ],
            align: 'center',
            size: 100,
          },
        ]),
        x: aspect.width * 0.05,
        width: aspect.width * 0.9,
        y: aspect.height * 0.4,
        fontSize: 100,
      });
      const subHeaderElement = createTextElement({
        slateTextValue: JSON.stringify([
          {
            type: 'paragraph',
            children: [
              { text: 'Spark curiousity', color: textColor || theme.colors.black },
            ],
            align: 'center',
            size: 60,
          },
        ]),
        x: aspect.width * 0.05,
        y: aspect.height * 0.55,
        width: aspect.width * 0.9,
        fontSize: 60,
      });
      return {
        ...emptySlide,
        elements: [headerElement, subHeaderElement],
      };
    }
    if (contentType === 'image') {
      const headerElement = createTextElement({
        slateTextValue: JSON.stringify([
          {
            type: 'paragraph',
            children: [
              { text: 'A curipod slide', color: textColor || theme.colors.black },
            ],
            align: 'center',
            size: 100,
          },
        ]),
        x: aspect.width * 0.05,
        width: aspect.width * 0.9,
        y: aspect.height * 0.1,
        fontSize: 100,
      });

      const mediaElement = createImageElement({
        x: aspect.width * 0.5 - mediaData.width / 2,
        y: aspect.height * 0.3,
        width: mediaData.width,
        height: mediaData.height,
        url: mediaData.url,
        mediaMimeType: 'image/unsplash',
      });
      return {
        ...emptySlide,
        elements: [headerElement, mediaElement],
      };
    }
    if (contentType === 'image_text') {
      const headerElement = createTextElement({
        slateTextValue: JSON.stringify([
          {
            type: 'paragraph',
            children: [
              { text: 'A curipod slide', color: textColor || theme.colors.black },
            ],
            align: 'center',
            size: 100,
          },
        ]),
        x: aspect.width * 0.05,
        y: aspect.height * 0.1,
        fontSize: 100,
        width: aspect.width * 0.9,
      });

      const mediaElement = createImageElement({
        x: aspect.width * 0.05,
        y: aspect.height * 0.3,
        width: mediaData.width,
        height: mediaData.height,
        url: mediaData.url,
        mediaMimeType: 'image/unsplash',
      });

      const contentTextElement = createTextElement({
        slateTextValue: JSON.stringify([
          {
            type: 'paragraph',
            children: [{ text: randomTip, color: textColor || theme.colors.black }],
            align: 'left',
            size: 60,
          },
        ]),
        x: aspect.width * 0.55,
        y: aspect.height * 0.3,
        fontSize: 70,
        width: aspect.width * 0.4,
      });
      return {
        ...emptySlide,
        elements: [headerElement, mediaElement, contentTextElement],
      };
    }
    if (contentType === 'text_image') {
      const headerElement = createTextElement({
        slateTextValue: JSON.stringify([
          {
            type: 'paragraph',
            children: [
              { text: 'A curipod slide', color: textColor || theme.colors.black },
            ],
            align: 'center',
            size: 100,
          },
        ]),
        x: aspect.width * 0.05,
        y: aspect.height * 0.1,
        fontSize: 100,
        width: aspect.width * 0.9,
      });

      const mediaElement = createImageElement({
        x: aspect.width * 0.5,
        y: aspect.height * 0.3,
        width: mediaData.width,
        height: mediaData.height,
        url: mediaData.url,
        mediaMimeType: 'image/unsplash',
      });
      const contentTextElement = createTextElement({
        slateTextValue: JSON.stringify([
          {
            type: 'paragraph',
            children: [{ text: randomTip, color: textColor || theme.colors.black }],
            align: 'left',
            size: 60,
          },
        ]),
        x: aspect.width * 0.05,
        y: aspect.height * 0.3,
        fontSize: 70,
        width: aspect.width * 0.4,
      });
      return {
        ...emptySlide,
        elements: [headerElement, mediaElement, contentTextElement],
      };
    }
    if (contentType === 'fullscreen_media') {
      if (fullScreenEmpty) {
        return {
          ...emptySlide,
        };
      }
      let mediaElement;
      if (fullscreenThumbnail) {
        mediaElement = createImageElement({
          x: 0,
          y: 0,
          width: aspect.width,
          height: aspect.height,
          url: fullscreenThumbnail,
          mediaMimeType: 'image/unsplash',
        });
      } else {
        mediaElement = createIframeElement({
          url: 'https://www.youtube.com/embed/ea0TA4TIelY',
          x: 0,
          y: 0,
          iframeOrigin: 'youtube',
          width: aspect.width,
          height: aspect.height,
          lockedPosition: true,
        });
      }

      return {
        ...emptySlide,
        elements: [mediaElement],
      };
    }
  }

  // Activities
  if (
    type === 'poll' ||
    type === 'canvas' ||
    type === 'wordcloud' ||
    type === 'aiAssessedAnswers' ||
    type === 'aiAssessedImageAnswers' ||
    type === 'answers'
  ) {
    const titleElement = createTextElement({
      lockedPosition: true,
      lockedFromDeletion: true,
      slateTextValue: JSON.stringify([
        {
          type: 'paragraph',
          children: [
            {
              text: textValue || 'Activity title',
              color: textColor || theme.colors.black,
            },
          ],
          align: 'center',
          size: 100,
        },
      ]),

      fontSize: 100,
      x: aspect.width * 0.05,
      y: aspect.height * 0.1,
      width: aspect.width * 0.9,
    });
    let mediaElement;
    if (type === 'canvas' && includeMedia) {
      // fetched from manual uploaded image to DCN
      mediaData.width = 1104;
      mediaData.height = 661;
      mediaElement = createImageElement({
        x: aspect.width * 0.5 - mediaData.width / 2,
        y: aspect.height * 0.3,
        width: mediaData.width,
        height: mediaData.height,
        url:
          'https://storage.googleapis.com/curipod-images-cdn-bucket/drawing_activity.png',
        mediaMimeType: 'image/unsplash',
      });
    }
    if (type === 'answers' && includeMedia) {
      // fetched from manual uploaded image to DCN
      mediaData.width = 1065;
      mediaData.height = 570;
      mediaElement = createImageElement({
        x: aspect.width * 0.5 - mediaData.width / 2,
        y: aspect.height * 0.3,
        width: mediaData.width,
        height: mediaData.height,
        url:
          'https://storage.googleapis.com/curipod-images-cdn-bucket/answers_activity.png',
        mediaMimeType: 'image/unsplash',
      });
    }
    if (type === 'aiAssessedAnswers' && includeMedia) {
      // fetched from manual uploaded image to DCN
      mediaData.width = 1245;
      mediaData.height = 688;
      mediaElement = createImageElement({
        x: aspect.width * 0.5 - mediaData.width / 2,
        y: aspect.height * 0.3,
        width: mediaData.width,
        height: mediaData.height,
        url:
          'https://storage.googleapis.com/curipod-images-cdn-bucket/AIfeedback_activity.png',
        mediaMimeType: 'image/unsplash',
      });
    }
    if (type === 'aiAssessedImageAnswers' && includeMedia) {
      // fetched from manual uploaded image to DCN
      mediaData.width = 1245;
      mediaData.height = 688;
      mediaElement = createImageElement({
        x: aspect.width * 0.5 - mediaData.width / 2,
        y: aspect.height * 0.3,
        width: mediaData.width,
        height: mediaData.height,
        url:
          'https://storage.googleapis.com/curipod-images-cdn-bucket/aiDrawingFeedback.png',
        mediaMimeType: 'image/unsplash',
      });
    }
    if (type === 'wordcloud' && includeMedia) {
      // fetched from manual uploaded image to DCN
      mediaData.width = 1060;
      mediaData.height = 554;
      mediaElement = createImageElement({
        x: aspect.width * 0.5 - mediaData.width / 2,
        y: aspect.height * 0.3,
        width: mediaData.width,
        height: mediaData.height,
        url:
          'https://storage.googleapis.com/curipod-images-cdn-bucket/wordcloud_activity.png',
        mediaMimeType: 'image/unsplash',
      });
    }

    const durationSeconds = 60;
    const baseSlide: Slide = {
      ...emptySlide,
      durationSeconds,
      durationSecondsConfig: durationSeconds,
      elements: mediaElement ? [mediaElement, titleElement] : [titleElement],
      contextElements: [{ purpose: 'question', elementId: titleElement.id }],
    };
    if (type === 'poll') {
      baseSlide.durationSeconds = 30;
      const pollOptions = createPollOptions(textColor);
      baseSlide.pollOptions = pollOptions;
    }
    if (type === 'canvas') {
      baseSlide.durationSeconds = 300;
      baseSlide.durationSecondsConfig = 300;
      baseSlide.responseLimit = 1;
    }
    if (type === 'aiAssessedAnswers') {
      baseSlide.responseLimit = 1;
      baseSlide.prompts = [createAiFeedbackPrompt()];
    }
    if (type === 'aiAssessedImageAnswers') {
      baseSlide.responseLimit = 1;
      baseSlide.prompts = [createAiMultiModalFeedbackPrompt('science')];
      baseSlide.durationSeconds = 60 * 3;
      baseSlide.durationSecondsConfig = 60 * 3;
    }
    if (type === 'wordcloud') {
      baseSlide.responseLimit = 3;
    }
    if (type === 'answers') {
      baseSlide.responseLimit = 3;
    }
    return baseSlide;
  }
  if (type === 'voting') {
    const titleElement = createTextElement({
      lockedPosition: true,
      lockedFromDeletion: true,
      textValue,
      x: aspect.width * 0.05,
      fontSize: 100,
      width: aspect.width * 0.9,
      y: aspect.height * 0.1,
    });
    return {
      ...emptySlide,
      durationSeconds: 90,
      durationSecondsConfig: 90,
      elements: [titleElement],
    };
  }
  if (type === 'podium' || type === 'responsegrid') {
    return { ...emptySlide, elements: [] };
  }
  return { ...emptySlide, elements: [] };
};

export const createTextElement = (param?: {
  textValue?: string;
  slateTextValue?: string;
  width?: number;
  x?: number;
  y?: number;
  lockedPosition?: boolean;
  lockedFromDeletion?: boolean;
  fontSize?: number;
  textColor?: string;
}): SlideElementTextType => {
  const textValue = param?.slateTextValue
    ? param.slateTextValue
    : JSON.stringify([
        {
          type: 'paragraph',
          children: [
            {
              text: param?.textValue || '',
              color: param?.textColor || theme.colors.black,
            },
          ],
          align: 'left',
          size: param?.fontSize || 80,
        },
      ]);

  const width = param?.width || 1920 - 96;
  const widthPercentage = width / 1920;
  const heightCalculated = calculateHeightOfSlateText(textValue, widthPercentage);
  const { x, y } = getXandYForNewElement({
    x: param?.x,
    y: param?.y,
    width: param?.width,
  });
  const element: SlideElementTextType = {
    x,
    y,
    width: width,
    height: heightCalculated,
    opacity: 1,
    borderRadius: 0,
    lockedFromDeletion:
      param?.lockedFromDeletion !== undefined ? param?.lockedFromDeletion : false,
    lockedPosition: param?.lockedPosition !== undefined ? param?.lockedPosition : false,
    angle: 0,
    type: 'text',
    version: 1,
    textValue,
    id: generateUniqueId().toString(),
  };

  return element;
};
export const createFixedTextElement = (param?: {
  textValue?: string;
  slateTextValue?: string;
  width?: number;
  x?: number;
  y?: number;
  lockedPosition?: boolean;
  lockedFromDeletion?: boolean;
  fontSize?: number;
  textColor?: string;
}): SlideElementTextType => {
  const textValue = param?.slateTextValue
    ? param.slateTextValue
    : JSON.stringify([
        {
          type: 'paragraph',
          children: [
            { text: param?.textValue || '', color: param?.textColor || '#282F33' },
          ],
          align: 'left',
          size: param?.fontSize || 80,
        },
      ]);

  const width = param?.width || 1920 - 96;
  const widthPercentage = width / 1920;
  const heightCalculated = calculateHeightOfSlateText(textValue, widthPercentage);
  const { x, y } = getXandYForNewElementNoRandom({
    x: param?.x,
    y: param?.y,
    width: param?.width,
  });
  const element: SlideElementTextType = {
    x,
    y,
    width: width,
    height: heightCalculated,
    opacity: 1,
    borderRadius: 0,
    lockedFromDeletion:
      param?.lockedFromDeletion !== undefined ? param?.lockedFromDeletion : false,
    lockedPosition: param?.lockedPosition !== undefined ? param?.lockedPosition : false,
    angle: 0,
    type: 'text',
    version: 1,
    textValue,
    id: generateUniqueId().toString(),
  };

  return element;
};
export const createFigureElement = (
  param?:
    | {
        height?: number;
        width?: number;
        defaultColors?: string[];
        x?: number;
        y?: number;
        value?: string;
        mediaFileId?: string;
      }
    | undefined,
): SlideElementSvgType => {
  const { x, y } = getXandYForNewElement({
    x: param?.x,
    y: param?.y,
    width: param?.width,
    height: param?.height,
  });
  const newId = generateUniqueId().toString();
  const element: SlideElementSvgType = {
    id: newId,
    x,
    y,
    width: param?.width || 500,
    height: param?.height || 500,
    type: 'svg',
    opacity: 1,
    angle: 0,
    version: 1,
    borderRadius: 0,
    colors: param?.defaultColors ? [...param.defaultColors] : [theme.colors.greyDark],
    svgValue: param?.value || undefined,
    mediaFileId: param?.mediaFileId || undefined,
    mediaMimetype: 'image/svg+xml',
  };
  return element;
};
export const createShapeElement = (
  param?:
    | {
        height?: number;
        width?: number;
        defaultColors?: string[];
        x?: number;
        y?: number;
        clipPath?: string;
      }
    | undefined,
): SlideElementShapeType => {
  // random x position in the center of the slide
  const { x, y } = getXandYForNewElement({
    x: param?.x,
    y: param?.y,
    width: param?.width,
    height: param?.height,
  });

  const newId = generateUniqueId().toString();
  const element: SlideElementShapeType = {
    id: newId,
    x,
    y,
    width: param?.width || 500,
    height: param?.height || 500,
    type: 'shape',
    opacity: 1,
    angle: 0,
    version: 1,
    borderRadius: 0,
    colors: param?.defaultColors || [theme.colors.greyDark],
    shapeClipPath: param?.clipPath || undefined,
  };
  return element;
};
export const createImageElement = (
  param?:
    | {
        url?: string;
        width?: number;
        height?: number;
        x?: number;
        y?: number;
        mediaMimeType: MediaMimeType;
        mediaAltText?: string;
        mediaFileId?: string;
        mediaDownloadLocation?: string;
        mediaThumbnailUrl?: string;
        cropConfig?: CropConfig;
        objectFit?: MediaObjectFit;
        preventRandomPlacement?: boolean;
      }
    | undefined,
): SlideElementImageType => {
  const { x, y } = getXandYForNewElement({
    x: param?.x,
    y: param?.y,
    width: param?.width,
    height: param?.height,
  });
  const { x: nonRandomX, y: nonRandomY } = getNonRandomXandYForNewElement({
    x: param?.x,
    y: param?.y,
    width: param?.width,
    height: param?.height,
  });

  const element: SlideElementImageType = {
    id: generateUniqueId().toString(),
    x: param?.preventRandomPlacement ? nonRandomX : x,
    y: param?.preventRandomPlacement ? nonRandomY : y,
    width: param?.width || 800,
    height: param?.height || 600,
    type: 'image',
    mediaAltText: param?.mediaAltText || 'Image',
    mediaDownloadLocation: param?.mediaDownloadLocation || undefined,
    mediaMimetype: param?.mediaMimeType || 'image/unsplash',
    mediaThumbnailUrl: param?.mediaThumbnailUrl || undefined,
    mediaFileId: param?.mediaFileId || undefined,
    imageObjectFit: param?.objectFit || 'fill',
    angle: 0,
    borderRadius: 0,
    opacity: 1,
    version: 1,
    mediaCropConfig: param?.cropConfig || undefined,
    mediaUrl: param?.url || undefined,
  };
  return element;
};
export const createIframeElement = (
  param?:
    | {
        url?: string;
        altText?: string;
        x?: number;
        y?: number;
        width?: number;
        height?: number;
        lockedPosition?: boolean;
        iframeOrigin?: IframeAppSmash;
      }
    | undefined,
): SlideElementIframeType => {
  const element: SlideElementIframeType = {
    id: generateUniqueId().toString(),
    x: param?.x !== undefined ? param.x : 48,
    y: param?.y !== undefined ? param.y : 48,
    width: param?.width || 800,
    height: param?.height || 600,
    type: 'iframe',
    angle: 0,
    borderRadius: 0,
    opacity: 1,
    lockedPosition: param?.lockedPosition !== undefined ? param.lockedPosition : false,
    version: 1,
    mediaUrl: param?.url || `https://www.youtube.com/embed/2g811Eo7K8U`,
    iframeOrigin: param?.iframeOrigin || 'youtube',
  };
  return element;
};

export function createScaleModifier(scale: number): Modifier {
  return ({ transform }) => ({
    ...transform,
    x: transform.x / scale,
    y: transform.y / scale,
  });
}

export const colorIsHexCode = (color: string) => {
  const colorNames = ['black', 'white', 'red', 'green', 'blue'];
  if (color.startsWith('#')) {
    return /^#([A-Fa-f0-9]{3}([A-Fa-f0-9]{3})?|[A-Fa-f0-9]{6}([A-Fa-f0-9]{2})?|[A-Fa-f0-9]{8})$/.test(
      color,
    );
  }

  return colorNames.includes(color);
};

export const convertToCuripodSvg = (svg: string) => {
  const colorRegex = /(?:fill|stroke)="([^"]+)"/g;

  const colors: string[] = [];

  const widthMatch = /width="([^"]+)"/.exec(svg);
  const heightMatch = /height="([^"]+)"/.exec(svg);

  const width = widthMatch ? widthMatch[1] : '200';
  const height = heightMatch ? heightMatch[1] : '200';

  // Replace mask sections with placeholders
  const maskRegex = /<mask[^>]*>[\s\S]*?<\/mask>/g;
  let maskCounter = 0;
  const maskPlaceholders: string[] = [];
  const svgWithPlaceholders = svg.replace(maskRegex, () => {
    const placeholder = `###MASK${maskCounter}###`;
    maskPlaceholders.push(placeholder);
    maskCounter++;
    return placeholder;
  });

  // Extract colors from the SVG segments not including masks
  svgWithPlaceholders.match(colorRegex)?.forEach(match => {
    const color = match.split('"')[1];
    if (!colors.includes(color) && colorIsHexCode(color)) {
      colors.push(color);
    }
  });

  // Replace color in SVG
  let curipodSvg = svgWithPlaceholders.replace(colorRegex, (match, color) => {
    const index = colors.indexOf(color);
    if (!colorIsHexCode(color)) return match;
    if (match.includes('stroke')) return `stroke="{{color${index}}}"`;
    return `fill="{{color${index}}}"`;
  });

  // Re-insert mask sections
  const maskMatches = svg.match(maskRegex) || [];
  maskPlaceholders.forEach((placeholder, index) => {
    curipodSvg = curipodSvg.replace(placeholder, maskMatches[index] || '');
  });

  return {
    colors,
    width: parseInt(width),
    height: parseInt(height),
    curipodSvg,
  };
};

// used by the text element when resizing the new x and y position of the element
export const determineDxDy = ({
  d,
  direction,
}: {
  d: NumberSize;
  direction: Direction;
}) => {
  const dxdy = { dx: 0, dy: 0 };
  switch (direction) {
    case 'top':
      dxdy.dx = 0;
      dxdy.dy = -d.height;
      break;
    case 'topRight':
      dxdy.dx = 0;
      dxdy.dy = -d.height;
      break;
    case 'right':
      dxdy.dx = 0;
      dxdy.dy = 0;
      break;
    case 'bottomRight':
      dxdy.dx = 0;
      dxdy.dy = 0;
      break;
    case 'bottom':
      dxdy.dx = 0;
      dxdy.dy = 0;
      break;
    case 'bottomLeft':
      dxdy.dx = -d.width;
      dxdy.dy = 0;
      break;
    case 'left':
      dxdy.dx = -d.width;
      dxdy.dy = 0;
      break;
    case 'topLeft':
      dxdy.dx = -d.width;
      dxdy.dy = -d.height;
      break;
  }
  return dxdy;
};
export function listSlidesByReferences(startSlideId: string, slides: Slide[]) {
  const startSlide = slides.find(r => r.id === startSlideId);
  if (!startSlide) {
    throw new Error('Startround must exist in the rounds array');
  }

  let dataReferenceSlideId = startSlide.dataReferenceSlideId;
  const tempSlides = [startSlide] as Slide[];
  while (dataReferenceSlideId) {
    const relatedSlide = slides.find(r => r.id === dataReferenceSlideId);
    if (relatedSlide) {
      tempSlides.unshift(relatedSlide);
      dataReferenceSlideId = relatedSlide.dataReferenceSlideId;
    } else {
      dataReferenceSlideId = undefined;
    }
  }
  return tempSlides;
}
function findSlidesByReferencesReverse(startSlideId: string, slides: Slide[]) {
  const startSlide = slides.find(r => r.id === startSlideId);
  if (!startSlide) {
    return [];
  }

  const tempSlides = [startSlide];

  while (tempSlides.length <= 3) {
    const nextSlide = slides.find(r => r.dataReferenceSlideId === tempSlides[0].id);
    if (nextSlide) {
      tempSlides.unshift(nextSlide);
    } else {
      break;
    }
  }
  return tempSlides.reverse();
}

export function findRelatedSlidesWithIndex(startRoundId: string, slides: Slide[]) {
  const referenceSlides = findRelatedSlidesWithoutIndex(startRoundId, slides);

  return referenceSlides.map(s => ({
    ...s,
    index: slides.findIndex(slide => slide.id === s.id) + 1,
  }));
}
export const createPollElements = ({
  pollOptions,
  aspect,
  scale = 1,
}: {
  pollOptions: PollOptions[];
  aspect: { width: number; height: number };
  scale?: number;
}): SlideElement[] => {
  const y = aspect.height * 0.78;
  const paddings = aspect.width * 0.05;
  const gap = 10;
  const height = -1;
  const totalWidth = (aspect.width - paddings * 2) * scale;
  const width = totalWidth / pollOptions.length - gap;

  return pollOptions.map((option, index) => {
    return {
      angle: 0,
      borderRadius: 0,
      height: height,
      width: width - gap,
      x: paddings + (width + gap) * index,
      y: y,
      type: 'text',
      id: `${option.id}`,
      opacity: 1,
      version: 1,
      promptSourceId: option.promptSourceId,
      textValue: option.displayName,
      lockedPosition: true,
      isPollOption: true,
    };
  });
};

// Define the parameters for function
const minScale = 1;
const maxScale = 0.2;
const maxLength = 200;
const k = 0.2;
// Used for calculating how much the font size should be scaled when the element is resized
export const calculateFontSizeResizeScaler = (textValue?: string): number => {
  if (!textValue) return 1;
  const value = Math.min(textValue.length, maxLength);

  // Calculate the slope and intercept
  const slope = (k * (maxScale - minScale)) / maxLength;
  const intercept = maxScale - slope * maxLength;

  // Linear mapping
  const result = slope * value + intercept;
  return result;
};

const getXandYForNewElement = ({
  x,
  y,
  width = 0,
  height = 0,
}: {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}) => {
  const xToUser = 1920 / 2 - width / 2 - 50 + Math.random() * 100;
  const yToUser = 1080 / 2 - height / 2 - 50 + Math.random() * 100;
  return { x: x || xToUser, y: y || yToUser };
};
const getNonRandomXandYForNewElement = ({
  x,
  y,
  width = 0,
  height = 0,
}: {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}) => {
  const xToUser = 1920 / 2 - width / 2;
  const yToUser = 1080 / 2 - height / 2;
  return { x: x || xToUser, y: y || yToUser };
};
const getXandYForNewElementNoRandom = ({
  x,
  y,
  width = 0,
  height = 0,
}: {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}) => {
  const xToUser = 1920 / 2 - width / 2;
  const yToUser = 1080 / 2 - height / 2;
  return { x: x || xToUser, y: y || yToUser };
};
export const slideTypeToIcon = (type: SlideType) => {
  if (type === 'answers') return <QuestionsSvg />;
  if (type === 'aiAssessedAnswers') return <InstantFeedbackSvg />;
  if (type === 'aiAssessedImageAnswers') return <AIImageFeedbackSvg />;
  if (type === 'podium') return <PodiumSvg />;
  if (type === 'canvas') return <DrawingsSvg />;
  if (type === 'responsegrid') return <GridSvg />;
  if (type === 'wordcloud') return <CloudSvg />;
  if (type === 'poll') return <PollSvg />;
  if (type === 'voting') return <VotingSvg />;
  return null;
};
export const slideToFirstRelevanSlideIcon = (slide: Slide, slides: Slide[]) => {
  const relevantSlides = findRelatedSlidesWithoutIndex(slide.id, slides);
  const firstRelevantSlide = relevantSlides[0];
  const type = firstRelevantSlide.type;
  return slideTypeToIcon(type);
};

export const moveSlide = ({
  fromSlideId,
  slides,
  toSlideId,
  before,
}: {
  fromSlideId: string;
  toSlideId: string;
  slides: Slide[];
  before: boolean;
}) => {
  // TODO - make this more performant on large arrays
  // TODO before we release custom slides - make unit tests for this function
  if (slides.length === 0) return [];
  const relatedSlides = findRelatedSlidesWithoutIndex(fromSlideId, slides);
  const relatedSlideIds = relatedSlides.map(r => r.id);
  const newArray = slides.filter(r => !relatedSlideIds.includes(r.id));

  const destinationRelatedSlides = findRelatedSlidesWithoutIndex(toSlideId, slides);

  // could use .at but we dont have polyfill yet
  const destinationSlideId = before
    ? destinationRelatedSlides[0]?.id
    : destinationRelatedSlides[destinationRelatedSlides.length - 1]?.id;

  const destinationSlideIndex = newArray.findIndex(r => r.id === destinationSlideId);
  const newIndex = before ? destinationSlideIndex : destinationSlideIndex + 1;
  newArray.splice(newIndex, 0, ...relatedSlides);
  return newArray;
};

export function getInsertIndex(allSlides: Slide[], slideId?: string) {
  // returns the index where related slides should be insterted into the flat slide array
  if (!slideId) {
    return allSlides.length > 0 ? allSlides.length : 0;
  }
  const relatedSlides = findRelatedSlidesWithoutIndex(slideId, allSlides);
  const lastRelatedSlideIndex = allSlides.findIndex(
    r => r.id === relatedSlides[relatedSlides.length - 1].id,
  );
  return lastRelatedSlideIndex + 1;
}

const insertRelatedSlides = (arr: Slide[], index: number, ...newItems: Slide[]) => [
  ...arr.slice(0, index),
  ...newItems,
  ...arr.slice(index),
];

export const insertSlidesAfterSelectedSlide = (
  slides: Slide[],
  newSlides: Slide[],
  selectedSlideId?: string,
): Slide[] => {
  const insertIndex = getInsertIndex(slides, selectedSlideId);

  const res = insertRelatedSlides(slides, insertIndex, ...newSlides);
  return res;
};

export const isEmptySlide = (slide?: Slide): boolean => {
  try {
    if (!slide) return false;
    const elements = slide.elements;
    if (elements.length === 0) return true;
    const textElements = elements.filter(e => isSlideElementText(e));
    const isEmpty = textElements.some(e => {
      if (!e.textValue) return false;
      const slateContent = parseSlateStringToText(e.textValue);
      if (slateContent.length === 0) return true;
      return false;
    });
    return isEmpty;
  } catch (e) {
    /* If we somehow fuck up this logic, we just make a new slide */
    return false;
  }
};

export function generateUniqueId() {
  return Math.floor(Math.random() * Date.now());
}

export function findRelatedSlidesWithoutIndex(startRoundId: string, slides: Slide[]) {
  const reverseRounds = findSlidesByReferencesReverse(startRoundId, slides);
  if (reverseRounds.length === 0) {
    return [];
  }
  const last = reverseRounds[reverseRounds.length - 1];

  const referenceRounds = listSlidesByReferences(last.id, slides);
  return referenceRounds;
}

export const retrieveSlideQuestionElement = (slide: Slide) => {
  const questionContextElement = (slide.contextElements || []).find(
    el => el.purpose === 'question',
  );

  if (!questionContextElement) return undefined;

  const questionElement = slide.elements.find(
    el => el.id === questionContextElement.elementId,
  );
  return questionElement;
};

export const retrieveSlideQuestionElementSlate = (slide: Slide) => {
  const questionElement = retrieveSlideQuestionElement(slide);
  return questionElement?.textValue;
};

export const getAggregatedSlidesAndActivities = (slides: Slide[]): Slide[] =>
  slides.filter(
    s => s.type !== 'voting' && s.type !== 'podium' && s.type !== 'responsegrid',
  );

export const createSlideGroups = (slides: Slide[]) => {
  const reversed = [...slides].reverse();
  const groups: Slide[][] = [];
  const parsedSlides: string[] = [];
  reversed.forEach(slide => {
    if (slide.type === 'slide') {
      groups.push([slide]);
      parsedSlides.push(slide.id);
    } else {
      if (parsedSlides.includes(slide.id)) return;
      const relatedSlides = listSlidesByReferences(slide.id, slides);
      relatedSlides.forEach(r => {
        parsedSlides.push(r.id);
      });
      groups.push(relatedSlides);
    }
  });
  return groups.reverse();
};

const tips = [
  'Understand the different learning styles and needs of your students and adapt your teaching methods accordingly.',
  'An open question followed by a group discussion is a great way of exploring a topic in depth.',
  'Introducing a new topic? Start with a word cloud and find out what your students already know.',
  'With Curipod you can use the AI-generated lesson live in the classroom to spark engagement and creativity.',
  'Use the moderator tool during Live to filter out inappropriate responses.',
  'Create a positive and inclusive classroom environment where all students feel comfortable and respected.',
  'Use a variety of teaching methods, such as lectures, discussions, and hands-on activities, to keep students engaged and interested.',
  'Use a variety of Curipod activities, such as drawings, polls, and open-ended questions to keep students engaged and interested.',
  'Provide regular and timely feedback on student progress to help them see their strengths and areas for improvement.',
  'Encourage student participation and make sure every student has an opportunity to speak and share their ideas.',
  'Plan and execute lessons that are aligned with the curriculum and aligned with the standards and goals of the school/district.',
  'Build positive relationships with your students by getting to know them as individuals and showing genuine interest in their lives and experiences.',
  'Continuously reflect on your teaching practice. Look for ways to improve and grow as an educator. #growthmindset',
  'Be flexible and adaptable to meet the changing needs of your students, especially during unexpected events like a pandemic or inclement weather.',
  'Establish clear objectives for your lessons and make sure students understand what they are expected to learn.',
  'Differentiate instruction to meet the needs of diverse learners, such as providing additional support or enrichment activities.',
  'Use real-life examples and connections to make the material relevant and meaningful to students.',
  'Encourage critical thinking, problem-solving, and creativity in your instruction.',
  'An AI lesson should always be considered a draft that needs the human touch from the teacher.',
  'Incorporate group work and collaborative learning activities to promote socialization and teamwork.',
  'Allow students to take ownership of their learning by giving them choice and autonomy.',
  'Provide opportunities for students to apply what they have learned through projects, performance tasks, and real-world scenarios.',
  'Use formative assessment strategies regularly to check for understanding and adjust instruction accordingly.',
  'Foster a growth mindset in your students by emphasizing effort, progress, and perseverance rather than just grades or test scores.',
  'Use positive reinforcement and recognize students for their efforts and successes.',
  'Provide ongoing professional development and stay up to date with the latest teaching strategies and research in your field.',
  'Use inclusive language and avoid stereotypes to create a safe and welcoming environment for all students, regardless of their background or identity.',
  'Use a variety of assessment methods, such as written tests, projects, presentations, and portfolios, to provide a more comprehensive understanding of student learning.',
  'Provide opportunities for students to give feedback on their learning experience and incorporate their suggestions into your teaching practice.',
  'Encourage students to ask questions and be curious, and provide resources for them to explore topics that interest them.',
  'Emphasize the importance of academic integrity and teach students how to cite sources and avoid plagiarism.',
  'Use humor and storytelling to make lessons more engaging and memorable for students.',
  'Create a classroom culture of respect and kindness by modeling positive behavior and addressing any instances of bullying or negative interactions among students.',
  'Collaborate with other teachers and education professionals to share ideas and learn from one another, and to develop a strong sense of community within your school.',
  'Embrace the uniqueness of each student and nurture their individual talents and strengths.',
  'Create a safe space where students feel comfortable taking risks and embracing their creativity.',
  'Celebrate progress, no matter how small. Every step forward is a victory.',
  'Continuously reflect on the impact of AI in education and adapt your teaching strategies accordingly.',
  'Teach students about the societal impact of AI, including its potential for bias and the need for diversity and inclusion in AI development.',
  'Encourage students to become lifelong learners, igniting their curiosity and thirst for knowledge.',
  'Remember that teaching is not just about imparting knowledge but also about shaping character and building resilience.',
  'Be a role model for your students, demonstrating empathy, compassion, and integrity in all that you do.',
  'Seek opportunities for professional growth and self-improvement, knowing that your own learning journey never ends.',
  'Build a strong support system with fellow educators who share your passion for teaching and can offer guidance and inspiration.',
  'Create a classroom where mistakes are seen as valuable learning opportunities, fostering a growth mindset in your students.',
  'Inspire students to think critically, question the world around them, and develop their own perspectives.',
  'Infuse your lessons with a sense of wonder and awe, encouraging students to marvel at the beauty of learning.',
  'Stay updated on the latest advancements in AI and incorporate relevant topics into your curriculum.',
  'Remember that each student has a story, and by showing genuine interest, you can make a profound impact on their lives.',
  'Emphasize the importance of empathy and understanding, nurturing a community of kindness and acceptance within your classroom.',
  'Encourage students to set goals and provide them with the support and resources needed to achieve their dreams.',
  'Take time to reflect on the successes and challenges of each day, finding joy in the progress you make as an educator.',
  'Remember that teaching is an art, and your passion and dedication are the colors that bring education to life.',
  'Seize teachable moments beyond the confines of textbooks, cultivating a love for learning that extends beyond the classroom.',
  'Create opportunities for cross-cultural understanding, celebrating diversity and fostering global citizenship among your students.',
  'Stay curious and embrace new technologies and teaching methods, adapting to the evolving needs of the 21st-century learner.',
  'Know that your impact extends far beyond the classroom walls, shaping the future by empowering the minds and hearts of your students.',
];

export const replacefigureColors = (figure?: string, colors?: string[]) => {
  if (!figure) return '';
  if (!colors) return figure;
  let replaced = figure;
  colors.forEach((color, index) => {
    replaced = replaced.replaceAll(`fill="{{color${index}}}"`, `fill="${color}"`);
  });
  colors.forEach((color, index) => {
    replaced = replaced.replaceAll(`stroke="{{color${index}}}"`, `stroke="${color}"`);
  });
  return replaced;
};

export const mergeVotingTextElementWithQuestionTextElementColor = (
  voteSlide: Slide,
  questionSlide: Slide,
): SlideElement[] => {
  /**
   * Takes the slate config of the vote text element and replaces the color from the question text element
   */
  const voteTitleElement = voteSlide.elements.find(e => e.type === 'text');
  if (!voteTitleElement) return voteSlide.elements;

  const voteTitleSlate = parseStringToSlateObject(voteTitleElement.textValue);

  const questionContextElement = questionSlide.contextElements.find(
    e => e.purpose === 'question',
  );
  const questionElement = questionSlide.elements.find(
    e => e.id === questionContextElement?.elementId,
  );
  if (!questionElement) return voteSlide.elements;

  const questionSlate = parseStringToSlateObject(questionElement.textValue);
  const questionFirstElement = questionSlate.find(e => e.type === 'paragraph');
  const questionTextChild = questionFirstElement?.children?.[0];
  const color =
    questionTextChild && isCuripodText(questionTextChild)
      ? questionTextChild.color || theme.colors.blackPure
      : questionFirstElement?.color || theme.colors.blackPure;

  const contextAwareVoteTitleElement: SlideElementTextType = {
    ...voteTitleElement,
    textValue: JSON.stringify(setColorOnAllSlateParagraphTexts(voteTitleSlate, color)),
  };
  return voteSlide.elements.map(e =>
    e.id === contextAwareVoteTitleElement.id ? contextAwareVoteTitleElement : e,
  );
};

export const MAX_ZOOM = 1.5;
export const MIN_ZOOM = 0.05;
export const zoomValueToPercentage = (zoom: number) => {
  const range = MAX_ZOOM - MIN_ZOOM;
  const percentage = ((zoom - MIN_ZOOM) / range) * 100;
  return percentage;
};

export const percentageToZoomValue = (percentage: number) => {
  const range = MAX_ZOOM - MIN_ZOOM;
  const zoom = (percentage * range) / 100 + MIN_ZOOM;
  return zoom;
};

export const getLowestYFromSlideElements = (elements: SlideElement[]) => {
  if (elements.length === 0) return;
  const firstElement = elements[0];
  const firstElementAngleRad = (firstElement.angle * Math.PI) / 180;
  const firstElementCornerCoordinates = getAllRectangleCornerCoordinate({
    x: firstElement.x,
    y: firstElement.y,
    w: firstElement.width,
    h: firstElement.height,
    theta: firstElementAngleRad,
  });
  const firstElementCornerCoordinatesLowestY = firstElementCornerCoordinates.reduce(
    (lowest, current) => {
      if (current.y < lowest.y) {
        return current;
      }
      return lowest;
    },
    firstElementCornerCoordinates[0],
  );

  if (elements.length === 1) return firstElementCornerCoordinatesLowestY;
  return elements.slice(1).reduce((lowest, current) => {
    const currentRad = (current.angle * Math.PI) / 180;
    const currentCor = getAllRectangleCornerCoordinate({
      x: current.x,
      y: current.y,
      w: current.width,
      h: current.height,
      theta: currentRad,
    });
    const currentLowestY = currentCor.reduce((lowest, current) => {
      if (current.y < lowest.y) {
        return current;
      }
      return lowest;
    }, currentCor[0]);
    if (currentLowestY.y < firstElementCornerCoordinatesLowestY.y) {
      return currentLowestY;
    }
    return lowest;
  }, firstElementCornerCoordinatesLowestY);
};

export const getHighestYFromSlideElements = (elements: SlideElement[]) => {
  if (elements.length === 0) return;
  const firstElement = elements[0];
  const firstElementAngleRad = (firstElement.angle * Math.PI) / 180;
  const firstElementCornerCoordinates = getAllRectangleCornerCoordinate({
    x: firstElement.x,
    y: firstElement.y,
    w: firstElement.width,
    h: firstElement.height,
    theta: firstElementAngleRad,
  });
  const firstElementCornerCoordinatesHighestY = firstElementCornerCoordinates.reduce(
    (highest, current) => {
      if (current.y > highest.y) {
        return current;
      }
      return highest;
    },
    firstElementCornerCoordinates[0],
  );

  if (elements.length === 1) return firstElementCornerCoordinatesHighestY;
  return elements.slice(1).reduce((highest, current) => {
    const currentRad = (current.angle * Math.PI) / 180;
    const currentCor = getAllRectangleCornerCoordinate({
      x: current.x,
      y: current.y,
      w: current.width,
      h: current.height,
      theta: currentRad,
    });
    const currentHighestY = currentCor.reduce((highest, current) => {
      if (current.y > highest.y) {
        return current;
      }
      return highest;
    }, currentCor[0]);
    if (currentHighestY.y > firstElementCornerCoordinatesHighestY.y) {
      return currentHighestY;
    }
    return highest;
  }, firstElementCornerCoordinatesHighestY);
};
export const getHighestXFromSlideElements = (elements: SlideElement[]) => {
  if (elements.length === 0) return;
  const firstElement = elements[0];
  const firstElementAngleRad = (firstElement.angle * Math.PI) / 180;
  const firstElementCornerCoordinates = getAllRectangleCornerCoordinate({
    x: firstElement.x,
    y: firstElement.y,
    w: firstElement.width,
    h: firstElement.height,
    theta: firstElementAngleRad,
  });
  const firstElementCornerCoordinatesHighestX = firstElementCornerCoordinates.reduce(
    (highest, current) => {
      if (current.x > highest.x) {
        return current;
      }
      return highest;
    },
    firstElementCornerCoordinates[0],
  );

  if (elements.length === 1) return firstElementCornerCoordinatesHighestX;
  return elements.slice(1).reduce((highest, current) => {
    const currentRad = (current.angle * Math.PI) / 180;
    const currentCor = getAllRectangleCornerCoordinate({
      x: current.x,
      y: current.y,
      w: current.width,
      h: current.height,
      theta: currentRad,
    });
    const currentHighestY = currentCor.reduce((highest, current) => {
      if (current.x > highest.x) {
        return current;
      }
      return highest;
    }, currentCor[0]);
    if (currentHighestY.x > firstElementCornerCoordinatesHighestX.x) {
      return currentHighestY;
    }
    return highest;
  }, firstElementCornerCoordinatesHighestX);
};

export const getLowestXFromSlideElements = (elements: SlideElement[]) => {
  if (elements.length === 0) return;
  const firstElement = elements[0];
  const firstElementAngleRad = (firstElement.angle * Math.PI) / 180;
  const firstElementCornerCoordinates = getAllRectangleCornerCoordinate({
    x: firstElement.x,
    y: firstElement.y,
    w: firstElement.width,
    h: firstElement.height,
    theta: firstElementAngleRad,
  });
  const firstElementCornerCoordinatesLowestX = firstElementCornerCoordinates.reduce(
    (lowest, current) => {
      if (current.x < lowest.x) {
        return current;
      }
      return lowest;
    },
    firstElementCornerCoordinates[0],
  );

  if (elements.length === 1) return firstElementCornerCoordinatesLowestX;
  return elements.slice(1).reduce((lowest, current) => {
    const currentRad = (current.angle * Math.PI) / 180;
    const currentCor = getAllRectangleCornerCoordinate({
      x: current.x,
      y: current.y,
      w: current.width,
      h: current.height,
      theta: currentRad,
    });
    const currentLowestY = currentCor.reduce((lowest, current) => {
      if (current.x < lowest.x) {
        return current;
      }
      return lowest;
    }, currentCor[0]);
    if (currentLowestY.x < firstElementCornerCoordinatesLowestX.x) {
      return currentLowestY;
    }
    return lowest;
  }, firstElementCornerCoordinatesLowestX);
};
