import { useEffect, useState } from 'react';

import { optimisticInitialWidthBeforeFirstActualRender } from './useIsDesktop';

export const isWidthDesktopSize = (width: number, breakPoint: number) =>
  width > Number.parseInt(`${breakPoint}px`, 10);

export const useBreakPoint = (breakPoint: number, defaultWidth?: number): boolean => {
  const [width, setWidth] = useState<number>(
    defaultWidth ? defaultWidth : optimisticInitialWidthBeforeFirstActualRender,
  );

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return isWidthDesktopSize(width, breakPoint);
};
