import React from 'react';

export const AIImageFeedbackSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_21182_2724)">
      <path
        d="M38.6539 27.3155L31.5323 40.7094C31.4307 40.9024 31.2921 41.0734 31.1243 41.2127C30.9565 41.3519 30.7629 41.4566 30.5545 41.5208C30.3461 41.5851 30.1271 41.6075 29.9101 41.5868C29.693 41.5661 29.4822 41.5028 29.2896 41.4005C29.0971 41.2981 28.9267 41.1587 28.7882 40.9903C28.6497 40.8219 28.5458 40.6278 28.4825 40.4192C28.4192 40.2105 28.3978 39.9914 28.4194 39.7744C28.441 39.5575 28.5053 39.3469 28.6085 39.1548L35.7432 25.736L37.5834 25.7447L38.6539 27.3155Z"
        fill="currentColor"
      />
      <path
        d="M39.5901 15.6957L43.9039 12.5923C44.0042 12.5201 44.1227 12.4775 44.246 12.4691C44.3693 12.4608 44.4924 12.4871 44.6015 12.5452C44.7107 12.6032 44.8014 12.6906 44.8634 12.7974C44.9254 12.9043 44.9563 13.0264 44.9526 13.1499L44.792 18.4617L49.0766 21.6053C49.1763 21.6784 49.2535 21.7779 49.2995 21.8926C49.3455 22.0073 49.3585 22.1326 49.3371 22.2543C49.3156 22.376 49.2605 22.4893 49.178 22.5813C49.0956 22.6733 48.989 22.7404 48.8703 22.7751L43.769 24.2637L42.1032 29.3103C42.0645 29.4277 41.9937 29.5318 41.8989 29.611C41.804 29.6903 41.6889 29.7414 41.5665 29.7586C41.4441 29.7758 41.3194 29.7584 41.2064 29.7084C41.0933 29.6584 40.9966 29.5777 40.927 29.4756L37.9348 25.0838L32.6206 25.0589C32.497 25.0583 32.3761 25.0232 32.2714 24.9575C32.1668 24.8917 32.0826 24.798 32.0285 24.687C31.9743 24.5759 31.9523 24.4519 31.9649 24.329C31.9775 24.2061 32.0243 24.0891 32.0999 23.9914L35.352 19.7883L33.7334 14.7267L33.7309 14.7185C33.695 14.601 33.6925 14.4759 33.7237 14.357C33.7548 14.2382 33.8184 14.1304 33.9073 14.0456C33.9962 13.9608 34.107 13.9025 34.2272 13.877C34.3474 13.8516 34.4723 13.8601 34.5879 13.9016L39.5901 15.6957Z"
        fill="currentColor"
      />
      <path
        d="M22.8252 16.1631C24.4756 15.6512 24.6863 14.9685 24.7603 14.3131C24.7765 14.17 24.8448 14.0379 24.9522 13.9419C25.0596 13.846 25.1985 13.793 25.3425 13.793C25.4865 13.793 25.6255 13.846 25.7329 13.9419C25.8403 14.0379 25.9086 14.17 25.9247 14.3131C25.9989 14.9684 26.2092 15.651 27.8598 16.1631C27.9882 16.2029 28.0991 16.2857 28.1737 16.3976C28.2483 16.5095 28.2821 16.6437 28.2695 16.7776C28.2569 16.9115 28.1987 17.0369 28.1045 17.1329C28.0103 17.229 27.886 17.2897 27.7524 17.3049C27.097 17.3789 26.4145 17.5893 25.9026 19.2399C25.8655 19.3594 25.7912 19.4639 25.6905 19.5381C25.5898 19.6123 25.468 19.6524 25.3429 19.6524C25.2178 19.6524 25.096 19.6123 24.9953 19.5381C24.8946 19.4639 24.8203 19.3594 24.7832 19.2399C24.2713 17.5895 23.5887 17.3789 22.9334 17.3049C22.7998 17.2897 22.6755 17.229 22.5813 17.1329C22.4872 17.0369 22.4289 16.9115 22.4163 16.7776C22.4037 16.6437 22.4376 16.5095 22.5122 16.3976C22.5868 16.2857 22.6976 16.2029 22.826 16.1631H22.8252Z"
        fill="currentColor"
      />
      <path
        d="M37.9115 33.2322C39.5619 32.7202 39.7726 32.0376 39.8466 31.3822C39.8628 31.2391 39.9311 31.107 40.0385 31.011C40.1459 30.9151 40.2848 30.8621 40.4288 30.8621C40.5728 30.8621 40.7118 30.9151 40.8192 31.011C40.9266 31.107 40.9949 31.2391 41.0111 31.3822C41.0852 32.0375 41.2955 32.72 42.9461 33.2322C43.0746 33.272 43.1854 33.3548 43.26 33.4667C43.3346 33.5786 43.3684 33.7128 43.3558 33.8467C43.3432 33.9805 43.285 34.106 43.1908 34.202C43.0966 34.2981 42.9723 34.3588 42.8387 34.374C42.1833 34.448 41.5008 34.6584 40.9889 36.309C40.9518 36.4285 40.8775 36.533 40.7768 36.6072C40.6761 36.6814 40.5543 36.7215 40.4292 36.7215C40.3041 36.7215 40.1823 36.6814 40.0816 36.6072C39.9809 36.533 39.9066 36.4285 39.8695 36.309C39.3576 34.6586 38.675 34.448 38.0197 34.374C37.8861 34.3588 37.7618 34.2981 37.6676 34.202C37.5735 34.106 37.5152 33.9805 37.5026 33.8467C37.49 33.7128 37.5239 33.5786 37.5985 33.4667C37.6731 33.3548 37.7839 33.272 37.9123 33.2322H37.9115Z"
        fill="currentColor"
      />
      <path
        d="M41.5455 11.2704C40.8902 11.3445 40.2077 11.5549 39.6957 13.2055C39.6587 13.325 39.5844 13.4295 39.4837 13.5037C39.383 13.5779 39.2612 13.618 39.1361 13.618C39.011 13.618 38.8892 13.5779 38.7885 13.5037C38.6878 13.4295 38.6134 13.325 38.5764 13.2055C38.0645 11.5551 37.3819 11.3445 36.7266 11.2704C36.593 11.2552 36.4687 11.1945 36.3745 11.0985C36.2803 11.0025 36.2221 10.877 36.2095 10.7431C36.1969 10.6092 36.2307 10.4751 36.3053 10.3632C36.3799 10.2513 36.4907 10.1685 36.6192 10.1286C38.2696 9.61673 38.4802 8.93411 38.5542 8.27864C38.5704 8.13555 38.6387 8.00344 38.7461 7.9075C38.8535 7.81157 38.9925 7.75854 39.1365 7.75854C39.2805 7.75854 39.4194 7.81157 39.5268 7.9075C39.6342 8.00344 39.7025 8.13555 39.7187 8.27864C39.7928 8.93401 40.0032 9.61653 41.6538 10.1286C41.7822 10.1685 41.893 10.2513 41.9676 10.3632C42.0422 10.4751 42.0761 10.6092 42.0635 10.7431C42.0509 10.877 41.9926 11.0025 41.8984 11.0985C41.8043 11.1945 41.6799 11.2552 41.5463 11.2704H41.5455Z"
        fill="currentColor"
      />
      <path
        d="M32.7651 34.0338L9.24299 40.3365C7.62143 40.771 5.94938 39.8057 5.51488 38.1841L0.787841 20.5426C0.353347 18.921 1.31871 17.2489 2.94026 16.8145L26.4623 10.5117C28.0839 10.0772 29.756 11.0426 30.1905 12.6642L34.9175 30.3057C35.352 31.9273 34.3866 33.5993 32.7651 34.0338ZM3.33418 18.2846C2.52414 18.5016 2.04092 19.3386 2.25797 20.1486L6.98502 37.7902C7.20207 38.6002 8.03902 39.0835 8.84907 38.8664L32.3712 32.5637C33.1812 32.3466 33.6644 31.5097 33.4474 30.6996L28.7203 13.0581C28.5033 12.248 27.6663 11.7648 26.8563 11.9819L3.33418 18.2846Z"
        fill="currentColor"
      />
      <path
        d="M10.1081 25.9237C8.48655 26.3582 6.8145 25.3929 6.38 23.7713C5.94551 22.1498 6.91087 20.4777 8.53242 20.0432C10.154 19.6087 11.826 20.5741 12.2605 22.1956C12.695 23.8172 11.7297 25.4892 10.1081 25.9237ZM8.92634 21.5133C8.1163 21.7304 7.63308 22.5674 7.85013 23.3774C8.06718 24.1874 8.90414 24.6707 9.71419 24.4536C10.5242 24.2366 11.0074 23.3996 10.7904 22.5896C10.5733 21.7795 9.73639 21.2963 8.92634 21.5133Z"
        fill="currentColor"
      />
      <path
        d="M6.71922 39.3269C6.53105 39.3773 6.32357 39.3557 6.14129 39.2517C5.7778 39.0418 5.65292 38.5758 5.86278 38.2123L10.9457 29.4084C11.5549 28.3534 13.0088 27.9638 14.0639 28.5729L16.6847 30.0861L20.5669 21.6874C20.8494 21.0776 21.3833 20.6241 22.033 20.4437L22.0492 20.4394C22.6931 20.2668 23.3781 20.3827 23.9297 20.7596L34.0222 27.6701C34.3691 27.9064 34.4583 28.3805 34.2205 28.7278C33.9842 29.0748 33.512 29.165 33.1628 28.9261L23.0703 22.0156C22.8836 21.8891 22.6622 21.8508 22.4431 21.9095C22.2867 21.9372 22.046 22.1152 21.9495 22.3254L17.7172 31.4812C17.6274 31.6754 17.4604 31.8226 17.2555 31.887C17.0507 31.9576 16.8302 31.9284 16.6452 31.8204L13.303 29.8908C12.9511 29.6889 12.4674 29.8185 12.2636 30.1693L7.18067 38.9732C7.07627 39.154 6.9074 39.2765 6.71922 39.3269Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_21182_2724">
        <rect width="50" height="50" fill="white" transform="matrix(-1 0 0 1 50 0)" />
      </clipPath>
    </defs>
  </svg>
);

export default AIImageFeedbackSvg;
