import React from 'react';

export const VideoActivitySvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1496 1.75635H4.66023C2.98148 1.75635 1.66669 3.06072 1.66669 4.72593V10.6874C1.66669 12.3547 2.98148 13.6609 4.66023 13.6609H5.95231C6.21523 13.6609 6.42856 13.4478 6.42856 13.1847C6.42856 12.9218 6.21544 12.7084 5.95231 12.7084H4.66023C3.51565 12.7084 2.61898 11.8207 2.61898 10.6872V4.72593C2.61898 3.59468 3.51565 2.70864 4.66023 2.70864H15.1494C16.359 2.70864 17.3809 3.63239 17.3809 4.72593V6.04197C17.3809 6.30489 17.594 6.51822 17.8571 6.51822C18.1202 6.51822 18.3334 6.3051 18.3334 6.04197V4.72593C18.3334 3.11635 16.8752 1.75635 15.1496 1.75635V1.75635Z"
      fill="currentColor"
    />
    <path
      d="M9.32024 10.435L11.8046 8.49813C12.0742 8.29772 12.2352 7.98313 12.2352 7.65647C12.2352 7.36022 12.1005 7.0898 11.8692 6.91813L9.31149 4.97147C9.1392 4.84334 8.94441 4.77563 8.74816 4.77563C8.24858 4.77563 7.85712 5.20959 7.85712 5.76313V9.6498C7.85712 10.2033 8.24837 10.6373 8.74816 10.6373C8.94441 10.6373 9.1392 10.5696 9.32024 10.435V10.435ZM8.80962 5.78626L11.2827 7.65522C11.2842 7.6673 11.2727 7.7073 11.228 7.74063L8.80982 9.62334V5.78626H8.80962Z"
      fill="currentColor"
    />
    <path
      d="M16.4498 13.7099C17.4086 13.7099 18.1886 12.9299 18.1886 11.9709C18.1886 11.0119 17.4086 10.2319 16.4498 10.2319C15.4911 10.2319 14.7111 11.0119 14.7111 11.9709C14.7111 12.063 14.7244 12.1515 14.7384 12.2399L12.2115 13.7636L9.35359 12.0405C9.12796 11.9044 8.83546 11.9769 8.69984 12.2024C8.564 12.4274 8.6365 12.7201 8.86171 12.8559L11.2894 14.3199L8.86171 15.7838C8.63671 15.9196 8.564 16.2121 8.69984 16.4374C8.78942 16.5857 8.94671 16.6678 9.10817 16.6678C9.19192 16.6678 9.27671 16.6457 9.35379 16.5992L12.2117 14.8761L14.723 16.3903C14.7205 16.4288 14.7113 16.4657 14.7113 16.5046C14.7113 17.4636 15.4913 18.2436 16.45 18.2436C17.4088 18.2436 18.1888 17.4636 18.1888 16.5046C18.1888 15.5459 17.4088 14.7659 16.45 14.7659C15.8767 14.7659 15.3713 15.0484 15.0544 15.4778L13.134 14.3199L15.1448 13.1074C15.4634 13.4732 15.9273 13.7099 16.4498 13.7099V13.7099ZM16.4498 11.1842C16.8834 11.1842 17.2363 11.5371 17.2363 11.9709C17.2363 12.4046 16.8836 12.7576 16.4498 12.7576C16.0163 12.7576 15.6634 12.4046 15.6634 11.9709C15.6634 11.5371 16.0161 11.1842 16.4498 11.1842V11.1842ZM16.4498 15.7182C16.8834 15.7182 17.2363 16.0709 17.2363 16.5046C17.2363 16.9384 16.8836 17.2913 16.4498 17.2913C16.0163 17.2913 15.6634 16.9384 15.6634 16.5046C15.6634 16.0709 16.0161 15.7182 16.4498 15.7182Z"
      fill="currentColor"
    />
  </svg>
);

export default VideoActivitySvg;
