import { useEffect, useState } from 'react';

export const optimisticInitialWidthBeforeFirstActualRender = 992;

export const isWidthDesktopSize = (width: number) => width > Number.parseInt('992px', 10);

export const useIsDesktop = (defaultWidth?: number) => {
  const optimisticWidth = defaultWidth
    ? defaultWidth
    : optimisticInitialWidthBeforeFirstActualRender;
  const [width, setWidth] = useState<number>();

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return isWidthDesktopSize(width ? width : optimisticWidth);
};
