export const SlideTextImageSvg = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5417 1H1.45833C0.654167 1 0 1.58875 0 2.3125V4.1875C0 4.91125 0.654167 5.5 1.45833 5.5H18.5417C19.3458 5.5 20 4.91125 20 4.1875V2.3125C20 1.58875 19.3458 1 18.5417 1Z"
      fill="currentColor"
    />
    <path
      d="M18.1406 8H11.8594C11.6315 8.00024 11.4131 8.08947 11.252 8.2481C11.0909 8.40673 11.0002 8.62181 11 8.84615V13.1538C11.0002 13.3782 11.0909 13.5933 11.252 13.7519C11.4131 13.9105 11.6315 13.9998 11.8594 14H18.1406C18.3685 13.9998 18.5869 13.9105 18.748 13.7519C18.9091 13.5933 18.9998 13.3782 19 13.1538V8.84615C18.9998 8.62181 18.9091 8.40673 18.748 8.2481C18.5869 8.08947 18.3685 8.00024 18.1406 8ZM11.4687 8.84615C11.4687 8.74415 11.5099 8.64632 11.5832 8.57419C11.6564 8.50206 11.7558 8.46154 11.8594 8.46154H18.1406C18.2442 8.46154 18.3436 8.50206 18.4168 8.57419C18.4901 8.64632 18.5312 8.74415 18.5312 8.84615V13.1538C18.5312 13.1642 18.5312 13.1742 18.5298 13.1846L16.5923 11.1502C16.5705 11.1271 16.5441 11.1088 16.5148 11.0962C16.4855 11.0836 16.4539 11.077 16.4219 11.0769C16.3898 11.0769 16.3581 11.0834 16.3287 11.0959C16.2993 11.1085 16.2729 11.1268 16.2509 11.1498L15.8102 11.6128L14.5328 10.2294C14.5108 10.2056 14.4841 10.1866 14.4542 10.1736C14.4243 10.1606 14.392 10.1538 14.3594 10.1538C14.3267 10.1538 14.2944 10.1606 14.2645 10.1736C14.2347 10.1866 14.2079 10.2056 14.1859 10.2294L11.4687 13.1718C11.4687 13.1658 11.4687 13.1598 11.4687 13.1538V8.84615ZM11.8594 13.5385C11.8303 13.5384 11.8013 13.5352 11.773 13.5289L14.3594 10.7277L15.4884 11.9506L14.9827 12.4818C14.9611 12.5039 14.9442 12.5299 14.933 12.5584C14.9218 12.5869 14.9164 12.6173 14.9172 12.6478C14.918 12.6784 14.9249 12.7085 14.9377 12.7364C14.9504 12.7643 14.9686 12.7894 14.9912 12.8103C15.0139 12.8312 15.0405 12.8475 15.0696 12.8582C15.0987 12.8688 15.1297 12.8737 15.1607 12.8725C15.1917 12.8713 15.2222 12.864 15.2504 12.8511C15.2785 12.8383 15.3038 12.82 15.3247 12.7974L16.4219 11.6451L18.2175 13.5309C18.1922 13.5359 18.1664 13.5385 18.1406 13.5385H11.8594Z"
      fill="currentColor"
    />
    <path
      d="M18.1406 8H11.8594C11.6315 8.00024 11.4131 8.08947 11.252 8.2481C11.0909 8.40673 11.0002 8.62181 11 8.84615V13.1538C11.0002 13.3782 11.0909 13.5933 11.252 13.7519C11.4131 13.9105 11.6315 13.9998 11.8594 14H18.1406C18.3685 13.9998 18.5869 13.9105 18.748 13.7519C18.9091 13.5933 18.9998 13.3782 19 13.1538V8.84615C18.9998 8.62181 18.9091 8.40673 18.748 8.2481C18.5869 8.08947 18.3685 8.00024 18.1406 8ZM11.4687 8.84615C11.4687 8.74415 11.5099 8.64632 11.5832 8.57419C11.6564 8.50206 11.7558 8.46154 11.8594 8.46154H18.1406C18.2442 8.46154 18.3436 8.50206 18.4168 8.57419C18.4901 8.64632 18.5312 8.74415 18.5312 8.84615V13.1538C18.5312 13.1642 18.5312 13.1742 18.5298 13.1846L16.5923 11.1502C16.5705 11.1271 16.5441 11.1088 16.5148 11.0962C16.4855 11.0836 16.4539 11.077 16.4219 11.0769C16.3898 11.0769 16.3581 11.0834 16.3287 11.0959C16.2993 11.1085 16.2729 11.1268 16.2509 11.1498L15.8102 11.6128L14.5328 10.2294C14.5108 10.2056 14.4841 10.1866 14.4542 10.1736C14.4243 10.1606 14.392 10.1538 14.3594 10.1538C14.3267 10.1538 14.2944 10.1606 14.2645 10.1736C14.2347 10.1866 14.2079 10.2056 14.1859 10.2294L11.4687 13.1718C11.4687 13.1658 11.4687 13.1598 11.4687 13.1538V8.84615ZM11.8594 13.5385C11.8303 13.5384 11.8013 13.5352 11.773 13.5289L14.3594 10.7277L15.4884 11.9506L14.9827 12.4818C14.9611 12.5039 14.9442 12.5299 14.933 12.5584C14.9218 12.5869 14.9164 12.6173 14.9172 12.6478C14.918 12.6784 14.9249 12.7085 14.9377 12.7364C14.9504 12.7643 14.9686 12.7894 14.9912 12.8103C15.0139 12.8312 15.0405 12.8475 15.0696 12.8582C15.0987 12.8688 15.1297 12.8737 15.1607 12.8725C15.1917 12.8713 15.2222 12.864 15.2504 12.8511C15.2785 12.8383 15.3038 12.82 15.3247 12.7974L16.4219 11.6451L18.2175 13.5309C18.1922 13.5359 18.1664 13.5385 18.1406 13.5385H11.8594Z"
      fill="currentColor"
    />
    <path
      d="M17.125 10.7692C17.3104 10.7692 17.4917 10.7151 17.6458 10.6137C17.8 10.5123 17.9202 10.3681 17.9911 10.1994C18.0621 10.0307 18.0807 9.84515 18.0445 9.66609C18.0083 9.48703 17.919 9.32255 17.7879 9.19346C17.6568 9.06436 17.4898 8.97645 17.3079 8.94083C17.126 8.90522 16.9375 8.9235 16.7662 8.99336C16.5949 9.06323 16.4485 9.18154 16.3455 9.33334C16.2425 9.48514 16.1875 9.6636 16.1875 9.84617C16.1878 10.0909 16.2867 10.3255 16.4624 10.4986C16.6382 10.6716 16.8764 10.769 17.125 10.7692V10.7692ZM17.125 9.38463C17.2177 9.38463 17.3083 9.4117 17.3854 9.46242C17.4625 9.51313 17.5226 9.58521 17.5581 9.66955C17.5935 9.75388 17.6028 9.84668 17.5847 9.93621C17.5667 10.0257 17.522 10.108 17.4565 10.1725C17.3909 10.2371 17.3074 10.281 17.2164 10.2988C17.1255 10.3166 17.0313 10.3075 16.9456 10.2726C16.86 10.2376 16.7868 10.1785 16.7352 10.1026C16.6837 10.0267 16.6562 9.93745 16.6562 9.84617C16.6562 9.72376 16.7056 9.60637 16.7935 9.51981C16.8815 9.43326 17.0007 9.38463 17.125 9.38463V9.38463Z"
      fill="currentColor"
    />
    <path
      d="M9.57812 8H1.42188C1.189 8 1 8.224 1 8.5C1 8.776 1.189 9 1.42188 9H9.57812C9.811 9 10 8.776 10 8.5C10 8.224 9.811 8 9.57812 8Z"
      fill="currentColor"
    />
    <path
      d="M9.57812 10.5H1.42188C1.189 10.5 1 10.724 1 11C1 11.276 1.189 11.5 1.42188 11.5H9.57812C9.811 11.5 10 11.276 10 11C10 10.724 9.811 10.5 9.57812 10.5Z"
      fill="currentColor"
    />
    <path
      d="M9.57812 8H1.42188C1.189 8 1 8.224 1 8.5C1 8.776 1.189 9 1.42188 9H9.57812C9.811 9 10 8.776 10 8.5C10 8.224 9.811 8 9.57812 8Z"
      fill="currentColor"
    />
    <path
      d="M9.57812 13H1.42188C1.189 13 1 13.224 1 13.5C1 13.776 1.189 14 1.42188 14H9.57812C9.811 14 10 13.776 10 13.5C10 13.224 9.811 13 9.57812 13Z"
      fill="currentColor"
    />
  </svg>
);
